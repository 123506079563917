import { messages } from 'AuthPart/authpart.messages';

export const DefaultError = messages['loginForm.error.default'];

export const loginErrors = new Map([
    [messages['server.username.error'], messages['server.email.cantFind.error']],
    [messages['server.password.error'], messages['server.password.invalid.error']],
    [messages['server.trial.error'], messages['loginForm.error.trialExpired']],
    [messages['server.company.error'], messages['loginForm.error.companyRemoved']],
]);
