import React, { FC, useEffect } from 'react';
import { Observer } from 'mobx-react';
import { createUseStyles, useTheme } from 'react-jss';
import { useParams, useHistory } from 'react-router-dom';
import { ThemeProps } from '@styles/theme';

import { SummaryPanel } from '@shared/components/SummaryPanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Avatar } from '@shared/components/Avatar';
import { RoutePaths } from '@core/routes/RoutePaths';
import { useUserAnalyticUIStore } from '@core/useStores';

import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

import AssignedIcon from '@assets/procedures.svg';
import CompletedIcon from '@assets/procedures-completed.svg';
import InProgressIcon from '@assets/procedures-in-progress.svg';
import { messages } from 'Analytics/analytic.messages';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    panelWrapper: {
        marginTop: -80,
        marginBottom: theme.spacing(6),
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    iconWrapper: {
        display: 'flex',
    },
    icon: {
        color: theme.colors.violet_primary,
        cursor: 'pointer',
    },
    avatar: {
        margin: [0, theme.spacing(3)],
    },
    loader: {
        color: theme.colors.violet_primary,
    },
}));

export const UserAnalyticsSummary: FC = () => {
    const theme = useTheme();
    const styles = useStyles(theme);
    const history = useHistory();
    const params: { id: string } = useParams();
    const { id } = params;

    const userAnalyticUIStore = useUserAnalyticUIStore();

    useEffect(() => {
        userAnalyticUIStore.loadStatistics({ id });
    }, [id]);

    return (
        <Observer>
            {() => {
                const { isStatisticsLoading, statistics } = userAnalyticUIStore;
                const userFirstName = statistics.userFirstName || '';
                const userLastName = statistics.userLastName || '';
                const userAvatar = statistics.userAvatar;

                const preparedData = [
                    {
                        id: 0,
                        title: messages['analyticsSummary.assigned'],
                        text: `${isStatisticsLoading
                            ? ''
                            : messages['analyticsSummary.proceduresCount'](statistics.assignedProcedures)}`,
                        icon: AssignedIcon,
                    },
                    {
                        id: 1,
                        title: messages['analyticsSummary.completed'],
                        text: `${isStatisticsLoading
                            ? ''
                            : messages['analyticsSummary.proceduresCount'](statistics.completedProcedures)}`,
                        icon: CompletedIcon,
                    },
                    {
                        id: 2,
                        title: messages['analyticsSummary.inProgress'],
                        text: `${isStatisticsLoading ? '' : messages['analyticsSummary.proceduresCount'](statistics.inProgress)}`,
                        icon: InProgressIcon,
                    },
                ];

                return (
                    <div className={styles.panelWrapper}>
                        <SummaryPanel
                            title={
                                isStatisticsLoading ? (
                                    <CircularProgress className={styles.loader} color='inherit' size={24} />
                                ) : (
                                    <div className={styles.title}>
                                        <span className={styles.iconWrapper} title={messages['analyticsSummary.backToList']}>
                                            <ArrowBackOutlinedIcon
                                                onClick={() => history.push(RoutePaths.analytics)}
                                                className={styles.icon}
                                            />
                                        </span>

                                        <Avatar
                                            className={styles.avatar}
                                            firstName={userFirstName}
                                            lastName={userLastName}
                                            image={userAvatar}
                                        />
                                        <Typography variant="h5">{userFirstName} {userLastName}</Typography>
                                    </div>
                                )}
                            items={preparedData}
                        />
                    </div>
                )
            }}
        </Observer>
    )
}
