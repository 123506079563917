import React from 'react'
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import { Form, FormikProps } from 'formik';

import { useRegisterUIStore } from '@core/useStores';
import { Button } from '@shared/components/Button';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { FormError } from '@shared/components/FormError';

import { FormValues } from './FormValues';
import { valuesSchema } from './valuesSchema';

import { useStyles } from './RegisterForm.styles';
import { messages } from '../register.messages';
import { APP_INNER_STYLE_VARIANT } from '@shared/components/forms/BaseInputProps';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_KEY } from '@core/api/constants';
import classNames from 'classnames';
export interface RegisterFormControlsProps extends FormikProps<FormValues> { };

export const RegisterFormControls: React.FC<RegisterFormControlsProps> = ({ errors, values }) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const registerUiStore = useRegisterUIStore();
    const formErrors = Object.values(errors);
    const onRequestClose = () => {
        registerUiStore.toggleRegisterModalOpen(false);

    }
    const onCaptchaChange = (value: string | null) => {
        registerUiStore.setCaptcha(value);
    }

    return (
        <Form autoComplete='off' noValidate>
            {
                formErrors && (
                    <div className={styles.errorWrapper}>
                        {
                            formErrors.map((error, index) => (
                                <FormError key={index} text={error || ''} />
                            ))
                        }
                    </div>
                )
            }
            <div className={styles.formWrapper}>
                <div className={styles.fieldsWrapper}>
                    <div className={classNames(styles.fieldWrapper, styles.halfWidth)} >
                        <FormikTextInput
                            schema={valuesSchema.companyName}
                            cleanable={true}
                            autoFocus={true}
                            styleVariant={APP_INNER_STYLE_VARIANT}
                        />
                    </div>
                    <div className={classNames(styles.fieldWrapper, styles.halfWidth)}>
                        <FormikTextInput
                            schema={valuesSchema.department}
                            cleanable={true}
                            autoFocus={true}
                            styleVariant={APP_INNER_STYLE_VARIANT}
                        />
                    </div>
                    <div className={classNames(styles.fieldWrapper)}>
                        <FormikTextInput
                            schema={valuesSchema.companyDescription}
                            cleanable={true}
                            autoFocus={true}
                            styleVariant={APP_INNER_STYLE_VARIANT}
                        />
                    </div>

                    <div className={classNames(styles.fieldWrapper)}>
                        <FormikTextInput
                            schema={valuesSchema.email}
                            cleanable={true}
                            autoFocus={true}
                            styleVariant={APP_INNER_STYLE_VARIANT}
                        />
                    </div>

                    <div className={classNames(styles.fieldWrapper, styles.halfWidth)}>
                        <FormikTextInput
                            schema={valuesSchema.name}
                            styleVariant={APP_INNER_STYLE_VARIANT}
                        />
                    </div>

                    <div className={classNames(styles.fieldWrapper, styles.halfWidth)}>
                        <FormikTextInput
                            schema={valuesSchema.surname}
                            styleVariant={APP_INNER_STYLE_VARIANT}
                        />
                    </div>
                    <div className={styles.fieldWrapper}>
                        <ReCAPTCHA
                            ref={registerUiStore.recaptchaRef}
                            sitekey={RECAPTCHA_KEY}
                            onChange={onCaptchaChange}
                        />
                    </div>
                </div>
                <div className={styles.actionWrapper}>
                    <Observer>
                        {() => (
                            <div className={styles.buttons}>
                                <Button
                                    onClick={onRequestClose}
                                    className={styles.button} color="secondary"
                                >
                                    {messages['registerForm.cancel']}
                                </Button>
                                <Button
                                    type='submit'
                                    styleVariant={APP_INNER_STYLE_VARIANT}
                                    loading={registerUiStore.status.isLoading}
                                >
                                    {messages['registerForm.register']}
                                </Button>
                            </div>
                        )}
                    </Observer>
                </div>
            </div>
        </Form>
    )
}
