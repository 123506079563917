import React, { FC } from 'react';
import { useTheme } from 'react-jss';

import { Button } from '@shared/components/Button';
import { useStyles } from '../../ProcedureModal.styles';
import { messages } from 'Procedures/procedures.messages';

export type DeviceActionConfirmationProps = {
    text: string,
    cancelText?: string,
    confirmText?: string,
    onConfirm: () => void,
    onRequestCancel: () => void,
};

export const DeviceActionConfirmation: FC<DeviceActionConfirmationProps> = ({
    text, cancelText = messages['deviceActionConfirmation.default.cancelText'],
    confirmText = messages['deviceActionConfirmation.default.confirmText'],
    onConfirm, onRequestCancel,
}) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.confirmationPanel}>
            <p
                className={styles.confirmationPanelText}
                data-testid="device-action-confirmation-text"
            >
                {text}
            </p>

            <div className={styles.confirmationPanelActions}>
                <Button onClick={onRequestCancel} color="secondary">
                    {cancelText}
                </Button>

                <Button onClick={onConfirm}>
                    {confirmText}
                </Button>
            </div>
        </div>
    )
}
