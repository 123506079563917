import React, { FC } from 'react';
import {
    useTable,
    useFlexLayout,
    useExpanded,
    usePagination,
    useSortBy,
    TableOptions,
    TableState,
} from 'react-table';
import { useTheme } from 'react-jss';
import { CircularProgress } from '@material-ui/core';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import classNames from 'classnames';

import { TablePagination } from './components/TablePagination';
import { useStyles } from './Table.styles';

export type PaginationProps = {
    currentPage: number,
    limit: number,
    isFirstPage: boolean,
    isLastPage: boolean,
    totalEntities: number,
    totalPages: number,
}

type Props = {
    renderRowSubComponent?: Function,
    className?: string,
    isLoading?: boolean,
    paginationDisabled?: boolean,
    paginationStatus: PaginationProps,
    initialState?: Partial<TableState>;
    fetchData?: (...args: any) => void,
};

export const Table: FC<Props & TableOptions<object>> = ({
    columns,
    data,
    renderRowSubComponent,
    className,
    isLoading,
    paginationDisabled,
    paginationStatus,
    initialState,
    fetchData = () => { },
}) => {
    const instance = useTable(
        {
            columns,
            data,
            autoResetPage: true,
            manualPagination: true,
            expandSubRows: false,
            manualSortBy: true,
            disableMultiSort: true,
            disableSortRemove: true,
            initialState: {
                sortBy: initialState?.sortBy,
            },
        },
        useFlexLayout,
        useSortBy,
        useExpanded,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        headers,
        prepareRow,
        rows,
        state: {
            pageIndex,
            pageSize,
            sortBy,
        },
    } = instance;

    React.useEffect(() => {
        fetchData({ pageIndex, pageSize, sortBy });
    }, [pageIndex, pageSize, sortBy])

    const goToPage = (pageIndex: number) => fetchData({ pageIndex });

    const theme = useTheme();
    const styles = useStyles({ theme });

    return (
        <div
            className={classNames(styles.table, className, { [styles.tableDisabled]: isLoading })}
            {...getTableProps()}
        >
            <div>
                <div className={styles.header}>
                    {
                        isLoading && (
                            <div className={styles.loadingWrapper}>
                                <CircularProgress className={styles.loader} color='inherit' size={24} />
                            </div>
                        )
                    }

                    {headers.map(column => {
                        const { key, style, ...props } = column.getHeaderProps(column.getSortByToggleProps({
                            title: null,
                        }));

                        const { minWidth, width, maxWidth } = column;

                        return (
                            <div
                                key={key}
                                className={classNames(styles.headerCell,
                                    {
                                        [styles.withSorting]: column.canSort,
                                    }
                                )}
                                style={{
                                    ...style,
                                    minWidth,
                                    maxWidth: maxWidth || 'none',
                                    // hack, set with in percents
                                    flexBasis: width ? `${width}%` : 'auto',
                                }}
                                {...props}
                            >
                                <span>
                                    {column.render('Header')}
                                </span>

                                {
                                    column.canSort && !column.isSorted && (
                                        <ArrowUpwardOutlinedIcon className={styles.sortPlaceholder} />
                                    )
                                }

                                {
                                    column.isSorted && (
                                        <div className={styles.sortIndicator}>
                                            <ArrowUpwardOutlinedIcon
                                                className={classNames(styles.sortIcon, { [styles.sortedDesc]: column.isSortedDesc })}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )
                    })}
                </div>

                <div className={styles.body} {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        const { key, ...props } = row.getRowProps();

                        return (
                            <div key={key} className={classNames(styles.row, row.original.isDisabled ? 'disabled' : '')} {...props}>
                                <div className={styles.defaultRowContent}>
                                    {row.cells.map(cell => {
                                        const { key, ...props } = cell.getCellProps();
                                        const { minWidth, width, maxWidth, disableEllipsis } = cell.column as any;
                                        const { style, ...restProps } = props;

                                        return (
                                            <div
                                                className={styles.cell}
                                                key={key}
                                                style={{
                                                    ...style,
                                                    minWidth,
                                                    maxWidth: maxWidth || 'none',
                                                    // hack, set with in percents
                                                    flexBasis: width ? `${width}%` : 'auto',
                                                }}
                                                {...restProps}
                                            >
                                                <span className={classNames({ [styles.ellipsis]: !disableEllipsis })}>
                                                    {cell.render('Cell')}
                                                </span>
                                            </div>
                                        )
                                    })}
                                </div>
                                {renderRowSubComponent && row.isExpanded && (
                                    <div className={styles.expandedRowContent}>
                                        {renderRowSubComponent({ row })}
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
            {!paginationDisabled &&
                (<TablePagination

                    paginationStatus={paginationStatus}
                    goToPage={goToPage}
                />)
            }
        </div>
    )
}
