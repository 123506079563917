import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useTheme } from 'react-jss';
import { toast } from 'react-toastify';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { FormikToggleInput } from '@shared/components/forms/formikWrappers/FormikToggle';
import { SelectOption } from '@shared/components/SelectDropdown/Option';
import { ToastMessage } from '@shared/components/Toast';
import { FormError } from '@shared/components/FormError';
import { useCompaniesGridUIStore, useCompanyUIStore } from '@core/useStores';

import { validationSchema } from './validationSchema';
import { valuesSchema } from './valuesSchema';

import { ICompanyStore } from 'Companies/stores';
import { messages } from 'Companies/companies.messages';

import { useStyles } from './CompanyModal.styles';
import ReactDatePicker from 'react-datepicker';
import { FormikDatePicker } from '@shared/components/forms/formikWrappers/FormikDatePicker/FormikDatePicker';
import { FormikSelectDropdown } from '@shared/components/forms/formikWrappers/FormikSelectDropdown';
import { convertOptionsToStrings, getOptionsByStrings } from '@shared/helpers/form';

export const COMMON_SELECT_PROPS = {
    isSearchable: true,
    hideSelectedOptions: false,
    isClearable: false,
    isMulti: true,
    closeMenuOnSelect: false,
    components: { Option: SelectOption },
};


export type CompanyModalProps = {
    isOpen: boolean,
    onRequestClose: () => void,
}

export class CompanyFormValues {
    name = '';
    description = '';
    dataIsolation = false;
    allowStandaloneBuild = false;
    expireAt = '';
    maxGroups = 9999;
    maxProcedures = 9999;
    maxUsers = 9999;
    maxLicensesCreator = 9999;
    maxLicensesPlayer = 9999;
    maxLaunchPasses = 9999;
    launchPassStartDate = '';
    isLaunchPassMonthly = false;
    admins = []
}

export const CompanyModal: FC<CompanyModalProps> = ({
    isOpen,
    onRequestClose,
}) => {
    const theme = useTheme();
    const styles = useStyles(theme);
    const [companyData, setCompanyData] = useState<ICompanyStore | null>(null);

    const companiesGridUIStore = useCompaniesGridUIStore();
    const companyUIStore = useCompanyUIStore();

    const isEditMode = !!companyUIStore.companyToEditId;

    useEffect(() => {
        const getCompanyData = async () => {
            if (isEditMode) {
                setCompanyData(companyUIStore.company);
                const result = await companyUIStore.loadCompany({ id: companyUIStore.companyToEditId });

                if (result.success) {
                    setCompanyData(result.data);
                }
            }
        }

        getCompanyData();
    }, [isEditMode]);

    const onDeleteCompany = () => {
        companyUIStore.toggleDeleteConfirmationModalOpened(true);
    }

    const onSubmit = async (values: CompanyFormValues) => {
        const { name, description, dataIsolation, allowStandaloneBuild, expireAt, maxUsers, maxProcedures, maxGroups, maxLicensesCreator, maxLicensesPlayer, maxLaunchPasses, launchPassStartDate, isLaunchPassMonthly, admins } = values;

        const computedValues = {
            name,
            description,
            dataIsolation,
            allowStandaloneBuild,
            expireAt,
            maxUsers,
            maxProcedures,
            maxGroups,
            maxLicensesCreator,
            maxLicensesPlayer,
            maxLaunchPasses,
            launchPassStartDate,
            isLaunchPassMonthly,
            admins: admins ? convertOptionsToStrings(admins) : [],
        }

        const result = isEditMode
            ? await companyUIStore.editCompany({ id: companyUIStore.companyToEditId, ...computedValues })
            : await companiesGridUIStore.createCompany.createCompanyRequest(computedValues);

        if (result.success) {
            companiesGridUIStore.load();

            onRequestClose();

            toast.success(
                <ToastMessage
                    message={messages['companyModal.submit.toast.success'](isEditMode)}
                    type='success'
                />
            );
        }
    }


    const companyName = companyData?.name || 'Company';
    const adminOptions = companyUIStore.getAdminsOptions;
    const initialValues = (isEditMode && companyData) ? {
        name: companyData.name || '',
        description: companyData.description || '',
        dataIsolation: companyData.dataIsolation || false,
        allowStandaloneBuild: companyData.allowStandaloneBuild || false,
        expireAt: companyData.expireAt || '',
        maxUsers: companyData.maxUsers || 9999,
        maxProcedures: companyData.maxProcedures || 9999,
        maxGroups: companyData.maxGroups || 9999,
        maxLicensesCreator: companyData.maxLicensesCreator || 9999,
        maxLicensesPlayer: companyData.maxLicensesPlayer || 9999,
        maxLaunchPasses: companyData.maxLaunchPasses || 0,
        launchPassStartDate: companyData.launchPassStartDate || '',
        isLaunchPassMonthly: companyData.isLaunchPassMonthly || false,
        admins: companyData.admins ? getOptionsByStrings(companyData.admins, adminOptions) : [],
    } : new CompanyFormValues();


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
        >
            <h2 className={styles.h2}>
                {isEditMode ? companyName : messages['companyModal.newCompany']}
            </h2>
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
            >
                {(form: FormikProps<CompanyFormValues>) => {
                    const fields = companiesGridUIStore.createCompany.getCompanyFields;
                    const storeErrors = companyUIStore.companyUIData.errors || fields.errors;

                    const formErrors: Record<string, any> = form.errors;
                    const errors = Object.keys(formErrors).length
                        ? Object.keys(formErrors)
                            .map((key: string) => formErrors[key].value ? formErrors[key].value : formErrors[key])
                        : storeErrors;

                    return (
                        <Form className={styles.form} autoComplete='off' noValidate>
                            <Observer>
                                {() => (
                                    <>
                                        {errors?.length && (
                                            <div className={styles.errors}>
                                                {errors.map((text: string) => (
                                                    <FormError className={styles.error} key={text} text={text} />
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </Observer>
                            <div className={styles.field}>
                                <FormikTextInput
                                    schema={valuesSchema.name}
                                    cleanable
                                />
                            </div>
                            <div className={styles.field}>
                                <FormikTextInput
                                    schema={valuesSchema.description}
                                    cleanable
                                />
                            </div>
                            <div className={styles.fieldWrapper}>
                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikTextInput
                                        schema={valuesSchema.maxUsers}
                                        type='number'
                                        min={0}
                                    />
                                </div>
                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikTextInput
                                        schema={valuesSchema.maxProcedures}
                                        type='number'
                                        min={0}
                                    />
                                </div>
                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikTextInput
                                        schema={valuesSchema.maxGroups}
                                        type='number'
                                        min={0}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldWrapper}>

                                <div className={classNames(styles.field, styles.halfWidth)}>
                                    <FormikTextInput
                                        schema={valuesSchema.maxLicensesCreator}
                                        type='number'
                                        min={0}
                                    />
                                </div>
                                <div className={classNames(styles.field, styles.halfWidth)}>
                                    <FormikTextInput
                                        schema={valuesSchema.maxLicensesPlayer}
                                        type='number'
                                        min={0}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldWrapper}>
                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikDatePicker
                                        schema={valuesSchema.expireAt}
                                    />
                                </div>

                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikToggleInput
                                        schema={valuesSchema.dataIsolation}
                                    />
                                </div>
                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikToggleInput
                                        schema={valuesSchema.allowStandaloneBuild}
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldWrapper}>
                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikTextInput
                                        schema={valuesSchema.maxLaunchPasses}
                                        type='number'
                                        min={0}
                                    />
                                </div>

                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikDatePicker
                                        schema={valuesSchema.launchPassStartDate}
                                    />
                                </div>
                                <div className={classNames(styles.field, styles.thirdWidth)}>
                                    <FormikToggleInput
                                        schema={valuesSchema.isLaunchPassMonthly}
                                    />
                                </div>
                            </div>
                            <div className={styles.field}>
                                <FormikSelectDropdown
                                    schema={valuesSchema.admins}
                                    className={styles.dropdown}
                                    options={adminOptions}
                                    {...COMMON_SELECT_PROPS}
                                />
                            </div>
                            <div className={classNames(styles.buttons, { [styles.end]: !isEditMode })}>
                                {
                                    //<div className={styles.helperWrapper}>{messages['companyModal.dataIsolation.helperText']}</div>

                                    isEditMode && (
                                        <Button
                                            onClick={onDeleteCompany}
                                            color="secondary"
                                        >
                                            {messages['companyModal.buttons.delete']}
                                        </Button>
                                    )
                                }
                                <div>
                                    <Button
                                        onClick={onRequestClose}
                                        className={styles.button} color="secondary"
                                    >
                                        {messages['companyModal.buttons.cancel']}
                                    </Button>
                                    <Button
                                        className={styles.button}
                                        type="submit"
                                    >
                                        {messages['companyModal.buttons.submit'](isEditMode)}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}
