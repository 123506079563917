import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import color from 'color';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

type ModalProps = {
    isOpen: boolean;
    children: React.ReactNode;
    className?: string;
    overlayClassName?: string;
    shouldCloseOnOverlayClick?: boolean;
    shouldCloseOnEsc?: boolean;
    onRequestClose: () => void;
};

const useStyles = createUseStyles((theme: ThemeProps) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        ...flex('', 'center', 'center'),
        zIndex: 2,
        backgroundColor: `${color(theme.colors.violet_darkest).fade(0.17)}`,
    },
    modal: {
        minHeight: 200,
        outline: 'none',
        position: 'relative',
        borderRadius: theme.radius,
        border: `1.5px solid ${theme.colors.grey_light}`,
        backgroundColor: theme.colors.white,
    },
    modalInner: {
        padding: theme.spacing(8),
        position: 'relative',
    },
}));

export const Modal: FC<ModalProps> = props => {
    const { children, overlayClassName, className, ...rest } = props;
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <ReactModal
            overlayClassName={classNames(styles.overlay, overlayClassName)}
            className={classNames(styles.modal, className)}
            {...rest}
        >
            <div className={styles.modalInner}>
                {children}
            </div>
        </ReactModal>
    )
}
