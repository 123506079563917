import { FormSchema } from '@shared/components/forms/FormSchema';

import { GroupFormValues } from './FormValues';
import { messages } from 'Groups/groups.messages';

type GroupFormSchema = FormSchema<GroupFormValues>;

export const FIELD_NAMES = {
    name: messages['groupModal.name.label'],
    users: messages['groupModal.users.label'],
    procedures: messages['groupModal.procedures.label'],
}

export const valuesSchema: GroupFormSchema = {
    name: {
        fieldName: 'name',
        label: FIELD_NAMES.name,
        isRequired: true,
    },
    users: {
        fieldName: 'users',
        label: FIELD_NAMES.users,
        isRequired: false,
        placeholder: '',
    },
    procedures: {
        fieldName: 'procedures',
        label: FIELD_NAMES.procedures,
        isRequired: false,
        placeholder: '',
    },
};
