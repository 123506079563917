import React from 'react'
import { useTheme } from 'react-jss';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import { useStyles } from './FormikPasswordInput.styles';
import { PasswordInputType } from './PasswordInputType';

type VisibilityTogglePrefixProps = {
    type: PasswordInputType;
    setType: (type: PasswordInputType) => void;
    value: string;
}

export const VisibilityTogglePrefix: React.FC<VisibilityTogglePrefixProps> = ({
    type, setType, value,
}) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const updateType = () => setType(
        type === PasswordInputType.PASSWORD
            ? PasswordInputType.TEXT
            : PasswordInputType.PASSWORD
    );

    return (
        value ? (
            <div
                className={styles.toggle}
                onClick={updateType}
                data-testid="visibility-toggle-button"
            >
                {
                    type === PasswordInputType.PASSWORD
                        ? <VisibilityOutlinedIcon />
                        : <VisibilityOffOutlinedIcon />
                }
            </div>
        ) : null
    )
}
