import React from 'react';
import { useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-table';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { IUserAnalyticStore } from '../../stores';
import { ExecutionsProgress } from './components';
import { RoutePaths } from '@core/routes/RoutePaths';
import { Avatar } from '@shared/components/Avatar';

import { useStyles } from './AnalyticsPage.styles';
import { messages } from '../../analytic.messages';

export const GridItem: React.FC<{ row: Row }> = ({ row }) => {
    const history = useHistory();
    const theme = useTheme()
    const styles = useStyles({ theme });

    const data = row.original as IUserAnalyticStore;

    const onUserClick = () => history.push(`${RoutePaths.analytics}/${data.userId}`);

    return (
        <div className={styles.gridItemWrapper}>
            <div className={styles.heading}>
                <div className={styles.userInfo}>
                    <Avatar
                        firstName={data.userFirstName}
                        lastName={data.userLastName}
                        image={data.userAvatar}
                        className={styles.avatar}
                    />
                    <span className={styles.userName}>
                        {data.userFirstName} {data.userLastName}
                    </span>
                </div>

                <span title={messages['analyticsPage.proceduresExecution']}>
                    <ArrowForwardIcon className={styles.goToUser} onClick={onUserClick} />
                </span>
            </div>

            <ExecutionsProgress
                completed={data.completedProcedures}
                assigned={data.assignedProcedures}
            />
        </div>
    )
}
