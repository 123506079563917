import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    wrapper: {
        width: '100%',
    },

    info: {
        ...flex('row', 'space-between', 'center'),
        marginBottom: theme.spacing(2.5),
    },

    text: {
        fontSize: 16,
        lineHeight: 1.5,
        color: theme.colors.black,
    },

    progressNumber: {
        fontSize: 16,
        lineHeight: 1.5,
        color: theme.colors.grey_text,
    },

    trackWrapper: {
        width: '100%',
        height: 4,
        background: theme.colors.grey_light,
    },

    progress: {
        height: 4,
        background: theme.colors.violet_primary,
    },
}));
