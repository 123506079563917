import { EndpointCallParams } from '@core/api/endpointCallParams';
import { ApiResponse } from '@ace/core';

import { IApiStore } from '@core/api/apiStore';

export type ImportUsersParams = {
    file: File,
}

// export const performImportUsers = async (api: IApiStore, params: EndpointCallParams<ImportUsersParams>) => {
//   const { payload, ...rest } = params;
//   const req = api
//     .getBlob('identity-app/api/v1/Account/ImportUsers', {
//       ...rest,
//       payload,
//     });

//   return req;
// }

export const performImportUsers = async (
    api: IApiStore,
    params: EndpointCallParams<ImportUsersParams>,
    progressHandler?: (percent: number) => void,
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const { file, ...uploadMetaData } = payload;

    const req = api
        .postAFile(
            'identity-app/api/v1/Account/ImportUsers',
            {
                ...rest,
                payload,
                progressHandler,
            },
            { fileField: 'file', file },
            { ...uploadMetaData },
        );

    return req;
}
