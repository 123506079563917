import React from 'react';
import { History } from 'history';
import { useTheme } from 'react-jss';
import { ModalPlaceholder } from '@ace/core';
import 'react-toastify/dist/ReactToastify.min.css';
import '@ace/core/lib/core.css';

import { ToastContainer } from '@shared/components/Toast';
import { modalService } from '@shared/components/Modal/modalService';
import '@styles/styles.scss';
import { useTypographyStyles } from '@styles/typography';

import { AppRouter } from './routes/AppRouter';

type Props = {
    history: History;
};

export const App: React.FC<Props> = ({ history }) => {
    const theme = useTheme()
    useTypographyStyles(theme);

    return (
        <div className="app">
            <AppRouter history={history} />
            <ModalPlaceholder history={history} modalService={modalService} />
            <ToastContainer />
        </div>
    )
};
