import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams.ts';
import { IApiStore } from '@core/api/apiStore';
import { EditProcedurePayload } from 'Procedures/domain/EditProcedurePayload';

export const performEditProcedure = async (
    api: IApiStore,
    params: EndpointCallParams<EditProcedurePayload>
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const { id, ...payloadBody } = payload;
    const req = api
        .put(`uiaggregator-app/api/v1/Procedures/${id}`, {
            ...rest,
            payload: payloadBody,
        })

    return req;
}
