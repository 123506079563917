import React, { useCallback, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useTheme } from 'react-jss';
import { Observer } from 'mobx-react';

import { Button } from '@shared/components/Button';
import { Avatar } from '@shared/components/Avatar';
import { useProfileUIStore } from '@core/useStores';

import { useStyles } from './UserAvatarView.styles';
import { messages } from '../../profile.messages';

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB in bytes;

export const UserAvatarView: React.FC = () => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const [uploadError, setUploadError] = useState('');
    const profileUIStore = useProfileUIStore();

    const onDeleteAvatar = async () => {
        await profileUIStore.deleteAvatar();
    };

    const onUploadAvatar = async (file: File) => {
        const result = await profileUIStore.uploadAvatar(file);

        if (result.success) {
            await profileUIStore.getAvatar();
        }
    };

    const onDropAccepted = useCallback(acceptedFiles => {
        setUploadError('');
        onUploadAvatar(acceptedFiles[0]);
    }, []);

    const onDropRejected = useCallback(() => {
        setUploadError(messages['profileAvatar.error.tooLarge']);
    }, []);

    const {
        getRootProps, getInputProps, open,
    } = useDropzone({
        accept: ['image/jpeg', 'image/png'],
        maxSize: MAX_FILE_SIZE,
        multiple: false,
        noKeyboard: true,
        onDropAccepted,
        onDropRejected,
    });

    const { onClick, ...rootProps } = getRootProps();

    return (
        <Observer>
            {() => (
                <div className={styles.wrapper} {...rootProps}>
                    <input {...getInputProps()} data-testid="avatar-file-view-input" />

                    <div className={styles.heading}>
                        <Typography variant="h5">{messages['profileAvatar.title']}</Typography>
                    </div>

                    <div className={styles.avatarWrapper}>
                        {
                            profileUIStore.status.isLoading && (
                                <CircularProgress className={styles.loader} color='inherit' size={48} />
                            )
                        }

                        <Avatar
                            className={styles.customAvatar}
                            firstName={profileUIStore.userInfo.firstName || ''}
                            lastName={profileUIStore.userInfo.lastName || ''}
                            image={profileUIStore.userInfo.avatar}
                        />
                    </div>

                    {
                        uploadError && <div className={styles.uploadError}>{uploadError}</div>
                    }

                    <Button
                        onClick={open}
                        className={styles.uploadButton}
                    >
                        {messages['profileAvatar.buttons.uploadNew']}
                    </Button>

                    {
                        profileUIStore.userInfo.avatar && (
                            <Button
                                onClick={onDeleteAvatar}
                                color="secondary"
                                data-testid="user-avatar-view-remove-button"
                            >
                                {messages['profileAvatar.buttons.remove']}
                            </Button>
                        )
                    }
                </div>
            )}
        </Observer>
    )
}
