import React from 'react';
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Typography from '@material-ui/core/Typography';

import { useGroupsUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { useStyles } from './GroupsHeading.styles';
import { messages } from 'Groups/groups.messages';

export const GroupsHeading: React.FC = () => {
    const theme = useTheme();
    const styles = useStyles({ theme });

    const groupsUIStore = useGroupsUIStore();
    const userPermissionsUIStore = useUserPermissionsUIStore();

    return (
        <div className={styles.headingWrapper}>
            <div className={styles.titleWrapper}>
                <Typography variant="h6">{messages['groupsHeading.title']}</Typography>

                {
                    userPermissionsUIStore.canCreateGroup && (
                        <span title={messages['groupsHeading.create.tooltip']}>
                            <AddOutlinedIcon
                                style={{ fontSize: 24 }}
                                className={styles.addIcon}
                                onClick={() => groupsUIStore.toggleGroupModalOpen(true)}
                            />
                        </span>
                    )
                }
            </div>

            <Observer>
                {() => (
                    <>
                        {
                            groupsUIStore.isLoading && (
                                <CircularProgress
                                    className={styles.loader}
                                    color='inherit'
                                    size={24}
                                />
                            )
                        }
                    </>
                )}
            </Observer>
        </div>
    )
};
