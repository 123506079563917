import * as Yup from 'yup';

import { FormValues } from './FormValues';
import { valuesSchema } from './valuesSchema';
import { messages } from '../register.messages';

export const errors = {
    email: messages['registerForm.validation.email'],
    required: messages['registerForm.validation.required'],
};

export const validationSchema = Yup.object().shape<FormValues>({
    companyName: Yup.string().required(errors.required(valuesSchema.companyName.label)),
    email: Yup.string().email(errors.email).required(errors.required(valuesSchema.email.label)),
    name: Yup.string().required(errors.required(valuesSchema.name.label)),
    surname: Yup.string().required(errors.required(valuesSchema.surname.label)),
});
