import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type GetCompanyParams = {
  id: string;
};
export type GetCompaniesParams = PageParams & SortParams & SearchParams;

/* OLDER METHOD WITHOUT PAGINATION AND SEARCH
export const performGetCompanies = async (api: IApiStore, params: EndpointCallParams<GetCompanyParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .get('identity-app/api/v1/Companies/', {
      ...rest,
      payload,
    })

  return req;
}
*/
export const performGetCompanies = async (api: IApiStore, params: EndpointCallParams<GetCompaniesParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .post('identity-app/api/v1/Companies/SearchCompanies', {
            ...rest,
            payload,
        })

    return req;
}
export const performGetCompany = async (api: IApiStore, params: EndpointCallParams<GetCompanyParams>) => {
    const { payload, ...rest } = params;
    const { id, ...restOfPayload } = payload;
    const req = api
        .get(`identity-app/api/v1/Companies/${id}`, {
            ...rest,
            ...restOfPayload,
        })

    return req;
}


type PageParams = {
  limit: number,
  page: number,
}
type SortParams = {
  sortDirection: number,
  sortFieldName: string,
}
type SearchParams = {
  searchText: string,
}

