import React from 'react';
import { Observer } from 'mobx-react';
import { createUseStyles, useTheme } from 'react-jss';
import { ThemeProps } from '@styles/theme';

import { SummaryPanel } from '@shared/components/SummaryPanel';

import UsersIcon from '@assets/users.svg';
import InstructorsIcon from '@assets/instructors.svg';

import { messages } from 'Licenses/license.messages';
import { ILicensesStatisticsStore } from 'Licenses/stores/LicensesStatistics.store';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    panelWrapper: {
        marginTop: -80,
        marginBottom: theme.spacing(4),
    },
}));
type LicensesSummaryProps = {
    statistics: ILicensesStatisticsStore
};
export const LicensesSummary: React.FC<LicensesSummaryProps> = ({ statistics }) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const renderValue = (value: number | null) => value === null ? '' : value;

    return (
        <Observer>
            {() => {

                const preparedData = [
                    {
                        id: 0,
                        title: messages['licensesSummary.player.title'],
                        text: messages['licensesSummary.player.text'](renderValue(statistics.playersCount)),
                        icon: UsersIcon,
                    },
                    {
                        id: 1,
                        title: messages['licensesSummary.creator.title'],
                        text: messages['licensesSummary.creator.text'](renderValue(statistics.creatorsCount)),
                        icon: InstructorsIcon,
                    }
                ];


                const title = messages['licensesSummary.title'];

                return (
                    <div className={styles.panelWrapper}>
                        <SummaryPanel
                            title={title}
                            items={preparedData}
                        />
                    </div>
                )
            }}
        </Observer>
    )
}
