import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const LicensesStatisticsStoreInferred = types
    .model({
        playersCount: types.optional(types.number, 0),
        creatorsCount: types.optional(types.number, 0),
    });

type LicensesStatisticsStoreFactoryType = typeof LicensesStatisticsStoreInferred;
interface ILicensesStatisticsStoreFactory extends LicensesStatisticsStoreFactoryType { }
export const LicensesStatisticsStore: ILicensesStatisticsStoreFactory = LicensesStatisticsStoreInferred;
export interface ILicensesStatisticsStore extends Instance<ILicensesStatisticsStoreFactory> { }
export interface ILicensesStatisticsStoreSnapshotIn extends SnapshotIn<ILicensesStatisticsStore> { }
