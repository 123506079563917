import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { setSize, flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    toggle: {
        top: 14,
        right: 12,
        position: 'absolute',
        ...setSize(24, 24),
        ...flex('', 'center', 'center'),
        color: theme.colors.transp_blue_darker,
        transition: 'transform .2s ease',
        cursor: 'pointer',

        '&:hover': {
            color: theme.colors.blue_primary,
            transformOrigin: 'center',
            transform: 'scale(1.2, 1.2)',
        },
    },
}));
