import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    touched: {},
    search: {
        position: 'absolute',
        left: 0,
        top: 1,
        width: 38,
        height: 38,
        ...flex('column', 'center', 'center'),
        borderRadius: [theme.radius, 0, 0, theme.radius],

        '&$touched': {
            backgroundColor: theme.colors.violet_primary,
        },
    },

    field: {
        paddingLeft: theme.spacing(11),
        paddingRight: theme.spacing(10),
    },

    icon: {
        color: theme.colors.violet_primary,

        '&$touched': {
            color: theme.colors.white,
        },
    },
}));
