export enum RootRoutes {
  Companies = 'companies',
  Users = 'users',
  Procedures = 'procedures',
  Collaboration = 'collaboration',
  Analytics = 'analytics',
  Licenses = 'licenses',
  Download = 'download',
  Profile = 'profile',
}

export enum AuthRoutes {
  Login = 'login',
  ForgotPassword = 'forgot-password',
  SetPassword = 'set-password',
}

export class RoutePaths {
  static readonly root = '/';
  static readonly companies = `${RoutePaths.root}${RootRoutes.Companies}`;
  static readonly users = `${RoutePaths.root}${RootRoutes.Users}`;
  static readonly procedures = `${RoutePaths.root}${RootRoutes.Procedures}`;
  static readonly collaboration = `${RoutePaths.root}${RootRoutes.Collaboration}`;
  static readonly analytics = `${RoutePaths.root}${RootRoutes.Analytics}`;
  static readonly licenses = `${RoutePaths.root}${RootRoutes.Licenses}`;
  static readonly download = `${RoutePaths.root}${RootRoutes.Download}`;
  static readonly analyticsByUser = `${RoutePaths.root}${RootRoutes.Analytics}/:id`;
  static readonly profile = `${RoutePaths.root}${RootRoutes.Profile}`;
  static readonly login = `${RoutePaths.root}${AuthRoutes.Login}`;
  static readonly forgotPassword = `${RoutePaths.root}${AuthRoutes.ForgotPassword}`;
  static readonly setPassword = `${RoutePaths.root}${AuthRoutes.SetPassword}`;
}
