import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams.ts';
import { IApiStore } from '@core/api/apiStore';

export type CancelDeviceProcessParams = {
    token: string;
    id: string;
};

export const performCancelDeviceProcess = async (
    api: IApiStore,
    params: EndpointCallParams<CancelDeviceProcessParams>
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const req = api
        .delete(`contentfileprovider-app/api/v1/FileProvider/Device/${payload.id}`, {
            ...rest,
            payload,
        })

    return req;
}
