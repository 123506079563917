import { ISelectOption } from './ISelectOption';

export const getValueLabelOptions = <T>(
    items: T[],
    getLabelCallback: (item: T) => string,
    getValueCallback: (item: T) => string,
    getDisabledCallback: (item: T) => boolean = () => false
): ISelectOption[] => {
    return items.map(item => ({
        value: getValueCallback(item),
        label: getLabelCallback(item),
        disabled: getDisabledCallback(item),
    }));
};
