import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams.ts';
import { IApiStore } from '@core/api/apiStore';

export type GetProcedureParams = {
    id: string;
};

export const performGetProcedure = async (
    api: IApiStore,
    params: EndpointCallParams<GetProcedureParams>
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const req = api
        .get(`uiaggregator-app/api/v1/Procedures/${payload.id}`, {
            ...rest,
            payload: {},
        })

    return req;
}
