import React from 'react';
import { MobXProviderContext } from 'mobx-react';

import { Stores } from './createStores';

import { IGroupsUIStore } from 'Groups/store';

import { ILoginUIStore } from 'AuthPart/Login';
import { ISetPasswordUIStore } from 'AuthPart/SetPassword';
import { IForgotPasswordUIStore } from 'AuthPart/ForgotPassword';
import { IUserPermissionsUIStore } from './Auth/stores/UserPermissionsUI.store';

import { IUsersGridUIStore, IUserUIStore } from 'Users/stores';

import { ICompaniesGridUIStore, ICompanyUIStore } from 'Companies/stores';

import { IProceduresGridUIStore, IProcedureUIStore } from 'Procedures/stores';

import { IAnalyticsGridUIStore, IUserAnalyticUIStore } from 'Analytics/stores';

import { IUserProfileUIStore } from '../Header/components/UserMenu/stores';
import { IProfileUIStore } from 'Profile/stores';
import { IRegisterUIStore } from 'Register/store/RegisterUIStore';
import { IDownloadablesUIStore } from 'Download/stores';
import { ILicensesUIStore } from 'Licenses/stores';

export const useAllStores = (): Stores => React.useContext(MobXProviderContext) as Stores;

export const useLoginUIStore = (): ILoginUIStore => useAllStores().loginUIStore;
export const useRegisterUIStore = (): IRegisterUIStore => useAllStores().registerUIStore;
export const useSetPasswordUIStore = (): ISetPasswordUIStore => useAllStores().setPasswordUIStore;
export const useForgotPasswordUIStore = (): IForgotPasswordUIStore => useAllStores().forgotPasswordUIStore;
export const useCompaniesGridUIStore = (): ICompaniesGridUIStore => useAllStores().companiesGridUIStore;
export const useCompanyUIStore = (): ICompanyUIStore => useAllStores().companyUIStore;
export const useUsersGridUIStore = (): IUsersGridUIStore => useAllStores().usersGridUIStore;
export const useUserUIStore = (): IUserUIStore => useAllStores().userUIStore;
export const useUserPermissionsUIStore = (): IUserPermissionsUIStore => useAllStores().userPermissionsUIStore;
export const useUserProfileUIStore = (): IUserProfileUIStore => useAllStores().userProfileUIStore;
export const useProfileUIStore = (): IProfileUIStore => useAllStores().profileUIStore;
export const useGroupsUIStore = (): IGroupsUIStore => useAllStores().groupsUIStore;
export const useProceduresUIStore = (): IProceduresGridUIStore => useAllStores().proceduresGridUIStore;
export const useProcedureUIStore = (): IProcedureUIStore => useAllStores().procedureUIStore;
export const useAnalyticsGridUIStore = (): IAnalyticsGridUIStore => useAllStores().analyticsGridUIStore;
export const useUserAnalyticUIStore = (): IUserAnalyticUIStore => useAllStores().userAnalyticUIStore;
export const useDownloadablesUIStore = (): IDownloadablesUIStore => useAllStores().downloadablesUIStore;
export const useLicensesUIStore = (): ILicensesUIStore => useAllStores().licensesUIStore;
