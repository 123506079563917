import React from 'react';
import { Formik } from 'formik';
import { useObserver } from 'mobx-react';
import { getEnv } from 'mobx-state-tree';
import { createUseStyles } from 'react-jss';

import { IStoresEnv } from '@core/storesEnv';
import { AppExternalSuccessMessage } from '@shared/components/AppExternalSuccessMessage';
import { ForgotPasswordFormControls } from './ForgotPasswordFormControls';
import { validationSchema } from './validationSchema';
import { FormValues } from './FormValues';
import { useForgotPasswordUIStore } from '@core/useStores';
import { messages } from 'AuthPart/authpart.messages';

const useStyles = createUseStyles({
    viewWrapper: {
        width: 468,
    },
});

export const ForgotPasswordForm: React.FC = () => {
    const forgotPasswordUiStore = useForgotPasswordUIStore();
    const { navigator } = getEnv<IStoresEnv>(forgotPasswordUiStore);
    const styles = useStyles();

    const sendForgotPasswordRequest = (values: FormValues) => {
        forgotPasswordUiStore.forgotPassword(values.email);
    }

    return useObserver(() => (
        forgotPasswordUiStore.status.isDone
            ? (
                <AppExternalSuccessMessage
                    text={messages['forgotPasswordForm.success'](forgotPasswordUiStore.email)}
                    navigator={navigator}
                />
            )
            : (
                <div className={styles.viewWrapper}>
                    <Formik
                        onSubmit={sendForgotPasswordRequest}
                        initialValues={new FormValues()}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        validateOnBlur={false}
                        validateOnChange={false}
                    >
                        {props => <ForgotPasswordFormControls {...props} />}
                    </Formik>
                </div>
            )
    ));
}
