import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';
import { DataState, DataStateStore } from '@ace/core';
import moment from 'moment';

import { IStoresEnv } from '@core/storesEnv';
import { RoutePaths } from '@core/routes/RoutePaths';

export const LoginUIStoreInferred = types
    .model('LoginUIStoreInferred', {
        authError: types.optional(types.string, ''),
    })
    .volatile(() => ({
        status: DataStateStore.create({ state: DataState.initial }),
    }))
    .actions(self => {
        const { navigator, api } = getEnv<IStoresEnv>(self);
        const { auth } = getEnv(api);

        const afterLoginNavigation = () => {
            if (auth.welcomePopupOpened) {
                navigator.to(RoutePaths.download);
            } else {
                navigator.to(RoutePaths.users);
            }

        };

        return ({
            login: flow(function* (email: string, password: string) {
                self.authError = '';
                self.status.setLoading();

                const result = yield auth.signIn(email, password);

                if (result.success) {
                    const { token, company, user, permissions, roles } = result.data.body || {};

                    self.status.setDone();

                    auth.saveUserInfo({
                        profile: { ...user },
                        company,
                        permissions,
                        roles,
                        token: {
                            ...token,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            expires_at: moment().unix() + token.expires_in,
                        },
                    });
                    auth.checkWelcomeScreen();
                    afterLoginNavigation();
                } else {
                    const { body } = result.error || {};
                    self.authError = body?.errors[0];
                    self.status.setError();

                }
            }),
        });
    });

type LoginUIStoreFactoryType = typeof LoginUIStoreInferred;
interface ILoginUIStoreFactory extends LoginUIStoreFactoryType { }
export const LoginUIStore: ILoginUIStoreFactory = LoginUIStoreInferred;
export interface ILoginUIStore extends Instance<ILoginUIStoreFactory> { }
export interface ILoginUIStoreSnapshotIn extends SnapshotIn<ILoginUIStore> { }
