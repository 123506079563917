import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type DeleteUserParams = {
    id: string;
};

export const performDeleteUser = async (api: IApiStore, params: EndpointCallParams<DeleteUserParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .delete(`identity-app/api/v1/Users/${payload.id}`, {
            ...rest,
            payload,
        })

    return req;
}
