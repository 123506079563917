import { Instance, SnapshotIn, types, flow, getEnv, getSnapshot, applySnapshot } from 'mobx-state-tree';

import {
    performGetUserAnalytic,
    performGetUserProceduresExecutions,
    performGetUserProcedureSteps,
    GetUserProcedureStepsParams,
    GetUserProceduresExecutionsParams,
    GetUserAnalyticParams
} from '../services';
import { IStoresEnv } from '@core/storesEnv';
import { UserAnalyticStatisticStore } from 'Analytics/stores/UserAnalyticStatistics.store';
import { ListProcedureStore } from 'Procedures/stores/ListProcedure.store';
import { ExecutionStepStore } from './ExecutionStep.store';
import { AnalyticsFiltersOptionsStore } from './AnalyticsFiltersOptions.store';
import { UserAnalyticPageQueryStore, requestParamsInitialState } from './UserAnalyticPageQuery.store';
import { ExportUserAnalyticsParams, performExportUserAnalytics } from 'Analytics/services/exportUserAnalytics';
import { ExportUserExecutionsParams, performExportUserExecutions } from 'Analytics/services/exportUserExecutions';

export const UserAnalyticStoreInferred = types
    .model('UserAnalyticStoreInferred', {
        userId: types.maybeNull(types.string),
        userFirstName: '',
        userLastName: '',
        userAvatar: types.maybeNull(types.string),
        completedProcedures: 0,
        assignedProcedures: 0,
        statistics: types.optional(UserAnalyticStatisticStore, {}),
        executedProcedures: types.maybeNull(types.array(ListProcedureStore)),
        notExecutedProcedures: types.maybeNull(types.array(ListProcedureStore)),
        allUsersAverage: '',
        bestTime: '',
        steps: types.maybeNull(types.array(ExecutionStepStore)),
        analyticsFilterOptions: types.optional(AnalyticsFiltersOptionsStore, {}),
        requestParams: types.optional(UserAnalyticPageQueryStore, requestParamsInitialState),
    })
    .views(self => ({
        get getUserAnalyticData() {
            return getSnapshot(self);
        },
    }))
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);
        const _resetExecutions = () => {
            self.executedProcedures = null;
            self.notExecutedProcedures = null;
        }

        const loadUserAnalytic = flow(function* (payload: GetUserAnalyticParams) {
            const result = yield performGetUserAnalytic(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (result.success) {
                self.statistics = result.data;
            }

            return result;
        });

        const loadUserProceduresExecutions = flow(function* (payload: GetUserProceduresExecutionsParams) {
            resetSteps();
            _resetExecutions();
            const result = yield performGetUserProceduresExecutions(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (result.success) {
                applySnapshot(self, { ...getSnapshot(self), ...result.data });
            }

            return result;
        });

        const loadUserProcedureSteps = flow(function* (payload: GetUserProcedureStepsParams) {
            const result = yield performGetUserProcedureSteps(
                api,
                {
                    payload,
                    disableDefaultErrorHandlers: true,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (result.success) {
                applySnapshot(self, { ...getSnapshot(self), ...result.data });
            } else {
                resetSteps();
            }

            return result;
        });

        const resetSteps = () => {
            self.steps = null;
            self.allUsersAverage = '';
            self.bestTime = '';
        }

        const exportAnalytics = flow(function* (payload: ExportUserAnalyticsParams) {
            const result = yield performExportUserAnalytics(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (!(result.data instanceof Blob)) { return; }
            const blob = new Blob([result.data]);
            const link = document.createElement('a');
            const filename = 'User-' + payload.id + '_Analytics.csv';
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        });

        const exportExecutions = flow(function* (payload: ExportUserExecutionsParams) {
            const result = yield performExportUserExecutions(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (!(result.data instanceof Blob)) { return; }
            const blob = new Blob([result.data]);
            const link = document.createElement('a');
            const filename = 'User-' + payload.id + '_Executions.csv';
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        });

        return {
            loadUserAnalytic,
            loadUserProceduresExecutions,
            loadUserProcedureSteps,
            resetSteps,
            exportAnalytics,
            exportExecutions,
        }
    })

type UserAnalyticStoreFactoryType = typeof UserAnalyticStoreInferred;
interface IUserAnalyticStoreFactory extends UserAnalyticStoreFactoryType { }
export const UserAnalyticStore: IUserAnalyticStoreFactory = UserAnalyticStoreInferred;
export interface IUserAnalyticStore extends Instance<IUserAnalyticStoreFactory> { }
export interface IUserAnalyticStoreSnapshotIn extends SnapshotIn<IUserAnalyticStore> { }
