import { createUseStyles } from 'react-jss';
import { flex, setSize } from '@styles/resources/mixins';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    disabledAction: {},
    hiddenAction: {},
    appExternal: {},

    actionWrapper: {
        ...setSize(24, 24),
        ...flex('', 'center', 'center'),
        borderRadius: theme.radius,
        position: 'absolute',
        cursor: 'pointer',
        border: 'none',
        padding: 0,
        right: theme.spacing(3),
        top: theme.spacing(1.5),
        fill: theme.colors.grey_light,
        background: 'transparent',

        '&:hover': {
            fill: theme.colors.violet_primary,
        },

        '&$appExternal': {
            top: theme.spacing(3.5),
            fill: theme.colors.transp_blue_darker,
            transition: 'transform .2s ease',

            '&:hover': {
                fill: theme.colors.blue_primary,
                transformOrigin: 'center',
                transform: 'scale(1.2, 1.2)',
            },
        },

        '& span': {
            // fix icon position
            position: 'relative',
            top: -2,
        },

        '&$hiddenAction': {
            visibility: 'hidden',
        },

        '&$disabledAction': {
            pointerEvents: 'none',
        },
    },

    iconStyle: {
        display: 'block',
        fill: 'inherit !important',
        width: 12,
        height: 12,
    },
}));
