import { Instance, SnapshotIn, types, flow } from 'mobx-state-tree';
import { Subject } from 'rxjs';

import { CreateProcedurePayload } from 'Procedures/domain/CreateProcedurePayload';
import { EditProcedurePayload } from 'Procedures/domain/EditProcedurePayload';
import { ProcedureStore } from './Procedure.store';
import { IProcedureDeviceUI } from './ProcedureDeviceUI.store';
import { ProcedureGeneralInfo, IProcedureGeneralInfo } from './ProcedureGeneralInfo.store';

export const ProcedureUIStoreInferred = types
    .model('ProcedureUIStoreInferred', {
        _procedure: types.optional(ProcedureStore, {}),
        processToken: types.maybeNull(types.string),
        isLoading: types.optional(types.boolean, false),
        isTokenLoading: types.optional(types.boolean, false),
        procedureModalOpened: types.optional(types.boolean, false),
        deleteConfirmationModalOpened: types.optional(types.boolean, false),
        procedureToEditId: types.maybeNull(types.string),
        currentDeviceIndex: types.optional(types.number, 0),
        errors: types.maybeNull(types.array(types.string)),
        procedureGeneralInfo: types.maybeNull(ProcedureGeneralInfo),
    })
    .views(self => {
        return {

            get devices() {
                return self._procedure.devices;
            },

            get isNextStepDisabled() {
                const devices = self._procedure.devices;
                const hasUploadedFile = devices.some(device => device._device?.deviceId);

                return devices.some(device => device.isLoading || device.hasError) || !hasUploadedFile;
            },

            get isNewDeviceAddDisabled() {
                const devices = self._procedure.devices;

                return devices.some(device =>
                    !device._device?.deviceId && !device.isLoading
                );
            },
        }
    })
    .actions(self => {
        const getToken = flow(function* () {
            self.isTokenLoading = true;
            const result = yield self._procedure.getToken();
            self.isTokenLoading = false;

            if (result.success) {
                self.processToken = result.data.token;
            }
        });

        const uploadFile = flow(function* (
            file: File, device: IProcedureDeviceUI, cancelSubject: Subject<{}>
        ) {
            if (self.processToken) {
                const additionalData = { token: self.processToken } as {
                    token: string;
                    procedureId?: string;
                    deviceId?: string;
                };

                if (self.procedureToEditId) {
                    additionalData['procedureId'] = self.procedureToEditId;
                }

                if (device._device?.deviceId) {
                    additionalData['deviceId'] = device._device?.deviceId;
                }

                const result = yield self._procedure.uploadFile(file, additionalData, device, cancelSubject);

                return result;
            }
        });

        const editProcedure = flow(function* (payload: EditProcedurePayload) {
            const result = yield self._procedure.editProcedure(payload);

            if (!result.success) {
                const { body } = result.error || {};

                self.errors = body?.messages;
            } else {
                self.errors = null;
            }

            return result;
        });

        const createProcedure = flow(function* (payload: CreateProcedurePayload) {
            const result = yield self._procedure.createProcedure(payload);

            if (!result.success) {
                const { body } = result.error || {};

                self.errors = body?.messages;
            } else {
                self.errors = null;
            }

            return result;
        });

        const deleteProcedure = flow(function* () {
            if (self.procedureToEditId) {
                return yield self._procedure.deleteProcedure(self.procedureToEditId);
            }
        });

        const exportProcedure = flow(function* (payload) {
            return yield self._procedure.exportProcedure(payload);
        });

        const toggleProcedureModalOpen = (open: boolean) => {
            self.procedureModalOpened = open;
        };

        const toggleDeleteConfirmationModalOpened = (open: boolean) => {
            self.deleteConfirmationModalOpened = open;
        };

        const setEditProcedureId = (id: string | null | undefined) => {
            if (id) {
                self.procedureToEditId = id;
            }
        };

        const clearEditProcedureId = () => self.procedureToEditId = null;

        const addDevice = () => {
            self._procedure.addDevice();
            setCurrentDeviceIndex(self._procedure.devices.length - 1);
        };

        const cancelProcedureFormProcess = flow(function* () {
            self._procedure.clearDevices();

            if (self.processToken) {
                return yield self._procedure.cancelProcedureFormProcess(self.processToken);
            }
        });

        const cancelProcedureDeviceProcess = flow(function* (id: string) {
            if (self.processToken) {
                return yield self._procedure.cancelProcedureDeviceProcess(self.processToken, id);
            }
        });

        const deleteDeviceFromList = (index: number) => {
            self._procedure.deleteDeviceFromList(index);
            setCurrentDeviceIndex(index > 0 ? index - 1 : 0);
        };

        const setProcedureGeneralInfo = (data: IProcedureGeneralInfo) => {
            self.procedureGeneralInfo = data;
        };

        const setCurrentDeviceIndex = (index: number) => {
            self.currentDeviceIndex = index;
        };

        const toggleLoading = (isLoading: boolean) => {
            self.isLoading = isLoading;
        };

        const cleanUpProcedureModal = () => {
            cancelProcedureFormProcess();
            setCurrentDeviceIndex(0);

            self.procedureGeneralInfo = null;
            self.procedureToEditId = null;
            self.processToken = null;
            self.errors = null;
        }

        return {
            getToken,
            uploadFile,
            editProcedure,
            createProcedure,
            deleteProcedure,
            exportProcedure,
            toggleProcedureModalOpen,
            toggleDeleteConfirmationModalOpened,
            setEditProcedureId,
            clearEditProcedureId,
            addDevice,
            cancelProcedureFormProcess,
            cancelProcedureDeviceProcess,
            setProcedureGeneralInfo,
            deleteDeviceFromList,
            setCurrentDeviceIndex,
            toggleLoading,
            cleanUpProcedureModal,
            setDevices: self._procedure.setDevices,
            loadProcedure: self._procedure.loadProcedure,
        }
    });

type ProcedureUIStoreFactoryType = typeof ProcedureUIStoreInferred;
interface IProcedureUIStoreFactory extends ProcedureUIStoreFactoryType { };
export const ProcedureUIStore: IProcedureUIStoreFactory = ProcedureUIStoreInferred;
export interface IProcedureUIStore extends Instance<IProcedureUIStoreFactory> { };
export interface IProcedureUIStoreSnapshotIn extends SnapshotIn<IProcedureUIStore> { };
