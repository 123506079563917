import { FormSchema } from '@shared/components/forms/FormSchema';

import { ProceduresFiltersFormValues } from './ProceduresFiltersModal';
import { messages } from 'Procedures/procedures.messages';

type ProceduresFilterFormSchema = FormSchema<ProceduresFiltersFormValues>;

export const valuesSchema: ProceduresFilterFormSchema = {
    devices: {
        fieldName: 'devices',
        label: messages['proceduresFiltersModal.devices.label'],
        isRequired: false,
        placeholder: '',
    },
    modes: {
        fieldName: 'modes',
        label: messages['proceduresFiltersModal.modes.label'],
        isRequired: false,
        placeholder: '',
    },
    types: {
        fieldName: 'types',
        label: messages['proceduresFiltersModal.types.label'],
        isRequired: false,
        placeholder: '',
    },
    collaboration: {
        fieldName: 'collaboration',
        label: messages['proceduresFiltersModal.collaboration.label'],
        isRequired: false,
        placeholder: '',
    },
    executionsMin: {
        fieldName: 'executionsMin',
        label: messages['proceduresFiltersModal.executionsMin.label'],
        type: 'number',
        isRequired: false,
        min: 0,
    },
    executionsMax: {
        fieldName: 'executionsMax',
        label: messages['proceduresFiltersModal.executionsMax.label'],
        type: 'number',
        isRequired: false,
    },
    groupIds: {
        fieldName: 'groupIds',
        label: messages['proceduresFiltersModal.groupIds.label'],
        isRequired: false,
        placeholder: '',
    },
    lastModified: {
        fieldName: 'lastModified',
        label: messages['proceduresFiltersModal.lastModified.label'],
        isRequired: false,
        placeholder: '',
    },
};
