import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type CreateCompanyParams = {
    name?: string;
    description?: string;
};

export const performCreateCompany = async (api: IApiStore, params: EndpointCallParams<CreateCompanyParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .post('identity-app/api/v1/Companies', {
            ...rest,
            payload,
        })

    return req;
}
