import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    goToUser: {
        color: theme.colors.violet_primary,
        fontSize: 24,

        '&:hover': {
            background: theme.colors.icon_background,
            borderRadius: theme.radius / 2,
            cursor: 'pointer',
        },
    },

    gridActions: {
        ...flex('row', 'space-between', 'center'),
        padding: [0, theme.spacing(4)],
        marginBottom: theme.spacing(4),
    },

    filters: {
        display: 'flex',
        alignItems: 'center',
    },

    pageTitle: {
        display: 'flex',
        alignItems: 'center',
    },

    loader: {
        color: theme.colors.violet_primary,
        marginLeft: theme.spacing(4),
    },

    searchWrapper: {
        width: 312,
    },

    gridItemWrapper: {
        width: `calc(33.333% - ${theme.spacing(4)}px)`,
        border: `1.5px solid ${theme.colors.grey_light}`,
        background: theme.colors.white,
        margin: [0, theme.spacing(2), theme.spacing(4)],
        marginBottom: theme.spacing(4),
        borderRadius: theme.radius,
    },

    heading: {
        padding: theme.spacing(6),
        ...flex('row', 'space-between', 'center'),
    },

    userInfo: {
        ...flex('row', 'flex-start', 'center'),
    },

    avatar: {
        marginRight: theme.spacing(3),
    },

    userName: {
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 600,
        color: theme.colors.black,
    },
}));
