import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

type StyleProps = {
    showBrush: boolean
}

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    usrBest: {},
    allAverage: {},
    noData: {},
    average: {}, // TODO: consider refactoring when populating with real data
    '@global': {
        '.recharts-surface': {
            overflow: 'visible',
        },
        '.recharts-brush-texts .recharts-text': {
            fontSize: 14,
        },
    },
    chart: ({ showBrush }: StyleProps) => ({
        padding: [theme.spacing(4), theme.spacing(7), showBrush ? theme.spacing(26) : theme.spacing(20)],
        backgroundColor: theme.colors.white,
        borderRadius: theme.radius,
        border: `2px solid ${theme.colors.grey_light}`,
        '&$noData': {
            padding: [theme.spacing(4), theme.spacing(4), theme.spacing(2)],
        },
    }),
    brush: {
        transform: 'translateY(90px)',
    },
    brushTicks: {
        display: 'flex',
        justifyContent: 'space-between',
        transform: 'translate(0, 90px)',
        width: 'calc(100% - 40px)',
        margin: '0 auto',
        color: theme.colors.grey_text,
    },
    tick: {
        position: 'relative',
        height: 16,
        fontSize: 12,
    },
    index: {
        position: 'relative',
        textAlign: 'center',
        width: '100%',
    },
    startTick: {
        position: 'absolute',
        left: '-50%',
    },
    finishTick: {
        position: 'absolute',
        right: '-50%',
    },
    tooltip: {
        borderRadius: theme.radius,
        backgroundColor: 'rgba(26, 32, 38, 0.9)',
        padding: [theme.spacing(1.5), theme.spacing(2)],
        width: 150,
    },
    row: {
        display: 'flex',
    },
    label: {
        marginBottom: theme.spacing(2),
        color: theme.colors.white,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    date: {
        fontSize: 12,
        lineHeight: 2,
        color: `${color(theme.colors.white).fade(0.38)}`,
        width: '35%',
    },
    time: {
        fontSize: 16,
        color: theme.colors.grey_light,
        lineHeight: 1.5,
        width: '65%',
        textAlign: 'right',
        '&$usrBest': {
            color: theme.colors.yellow,
        },
        '&$allAverage': {
            color: '#13c2c2', // TODO: replace with theme color
        },
    },
    yAxisLabel: {
        fill: theme.colors.grey_text,
        fontSize: 10,
        transform: 'translate(33px, -15px)',
    },
    header: {
        ...flex('', 'space-between', 'center'),
        marginBottom: theme.spacing(4),
    },
    title: {
        paddingRight: theme.spacing(6),
    },
    summary: {
        display: 'flex',
    },
    chip: {
        display: 'flex',
        alignItems: 'center',
        height: 20,
        borderRadius: theme.radius,
        fontSize: 12,
        lineHeight: 1.33,
        color: theme.colors.white,
        padding: [0, theme.spacing(1.5)],
        backgroundColor: theme.colors.yellow,
        marginRight: theme.spacing(2),
        '&$average': {
            backgroundColor: '#13c2c2',
        },
    },
    info: {
        ...flex('', '', 'center'),
        flexWrap: 'wrap',
    },
    filters: {
        display: 'flex',
    },
    filterSubElement: {
        width: '100%',
    },
    filter: {
        ...flex('', '', 'center'),
        marginLeft: theme.spacing(3),

    },
    h5: {
        paddingRight: theme.spacing(3),
    },
    dropdown: {
        width: 180,
    },
    loader: {
        color: theme.colors.violet_primary,
    },
}));
