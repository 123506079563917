import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type AddGroupParams = {
    name: string;
    userIds: string[];
    procedureIds: string[];
};

export const performAddGroup = async (api: IApiStore, params: EndpointCallParams<AddGroupParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .post('uiaggregator-app/api/v1/Groups', {
            ...rest,
            payload: {
                ...payload,
            },
        })

    return req;
}
