import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    errorWrapper: {
        marginBottom: theme.spacing(8),
        color: '#fff',
    },

    formWrapper: {
        margin: [0, 'auto'],
        width: 320,
    },

    fieldWrapper: {
        marginBottom: theme.spacing(4),
    },

    forgotPassWrapper: {
        textAlign: 'right',
        marginBottom: theme.spacing(8),
    },

    actionWrapper: {
        '& button': {
            width: '100%',
        },
    },
}));
