export const messages = {
    'header.logo.alt': 'WeaVR Manager logo',
    'mainMenu.companies': 'Companies',
    'mainMenu.users': 'Users',
    'mainMenu.procedures': 'Procedures',
    'mainMenu.collaboration': 'Collaboration',
    'mainMenu.analytics': 'Analytics',
    'mainMenu.licenses': 'Licenses',
    'mainMenu.download': 'Download',
    'userMenu.profile': 'My profile',
    'userMenu.requestOtp': 'Request OTP',
    'userMenu.logout': 'Logout',
}
