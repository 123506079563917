import React, { FC } from 'react';
import { useRef } from 'react';
import { useTheme, createUseStyles } from 'react-jss';
import { flex } from '@resources/mixins';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { ThemeProps } from '@styles/theme';
import { useGroupsUIStore, useUserProfileUIStore, useUserUIStore, useUsersGridUIStore } from '@core/useStores';
import { ToastMessage } from '@shared/components/Toast';
import { messages } from '@shared/shared.messages';
import classNames from 'classnames';
import ImportIcon from '@assets/import.svg';
import { ref } from 'yup';
import { FIELD_NAMES } from 'Companies/components/CompanyModal/valuesSchema';
import { UserUIStore } from 'Users/stores';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    button: {
        border: 'none',
        background: 'none',
        outline: 'none',
        padding: 0,
        height: 24,
        width: 24,
        ...flex('row', 'center', 'center'),

        '&:disabled': {
            opacity: .3,
        },

        '&:hover': {
            background: theme.colors.icon_background,
            borderRadius: theme.radius / 2,
            cursor: 'pointer',
        },
    },

    buttonImport: {
        width: 'auto',
        height: 'auto',
        fontFamily: theme.font.secondary,
        fontSize: 14,
        lineHeight: 1.4,
        fontWeight: 'normal',
        padding: [8, 8],
        borderRadius: theme.radius / 2,
        background: theme.colors.icon_background,
        color: theme.colors.violet_primary,
        '&:hover': {
            background: theme.colors.transp_violet,
        },
    },

    ImportIcon: {
        marginRight: 4,
    },

    loader: {
        color: theme.colors.violet_primary,
        marginRight: 4,
    },
}));

export const UsersImport: FC = () => {
    const theme = useTheme();
    const styles = useStyles({ theme });

    const usersUIStore = useUsersGridUIStore();
    const groupsUIStore = useGroupsUIStore();
    const userProfileUIStore = useUserProfileUIStore();

    const inputRef = useRef();

    const onClick = () => {
        // open file input box on click of another element
        inputRef.current.click();
    }

    const getFileExtension = fileName => {
        return fileName.split('.').pop();
    }

    const importUsers = async () => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        if (getFileExtension(fileObj.name) != 'csv') {
            toast.error(
                <ToastMessage
                    message={messages['import.toast.unsupported']}
                    type='error'
                />
            );

            return;
        }

        toast.success(
            <ToastMessage
                message={messages['import.toast.inProgress']}
                type='success'
            />
        );
        // const companyId = userProfileUIStore.userInfo.companyId;
        const result = await usersUIStore.importUsers({ file: fileObj });
        if (result.success) {
            // Forces a refresh of the page to load the new imported data
            window.location.reload();
        } else {
            const error = result?.error?.body?.messages
                ? result.error.body.messages[0] : 'Unspecified error';
            toast.error(
                <ToastMessage
                    message={error}
                    type='error'
                />
            );
        }
    }

    return (
        <Observer>
            {() => {
                const {
                    isImporting,
                    canImport,
                } = usersUIStore;

                return (
                    <>
                        <input
                            style={{ display: 'none' }}
                            ref={inputRef}
                            type="file"
                            accept=".csv"
                            onChange={importUsers}
                        />
                        <button
                            onClick={onClick}
                            className={classNames(styles.button, styles.buttonImport)}
                            disabled={!canImport}>
                            {isImporting && (
                                <CircularProgress className={styles.loader} color='inherit' size={24} />
                            )}
                            {!isImporting && (
                                <img title={messages['import.importButton.users']} src={ImportIcon}
                                    className={classNames(styles.ImportIcon)} />
                            )}
                            {
                                messages['import.importButton']
                            }
                        </button>
                    </>
                )
            }}
        </Observer>
    )
}
