import React, { FC } from 'react';
import {
    useTable,
    useFlexLayout,
    useExpanded,
    usePagination,
    useSortBy,
    TableOptions,
    TableState,
    Row,
} from 'react-table';
import { useTheme } from 'react-jss';
import classNames from 'classnames';

import { TablePagination } from '../Table/components/TablePagination';
import { PaginationStatus } from './paginationStatus';

import { useStyles } from './Grid.styles';

type Props = {
    className?: string,
    isLoading?: boolean,
    paginationStatus: PaginationStatus,
    initialState?: Partial<TableState>;
    fetchData?: (...args: any) => void,
    GridItem: React.FC<{ row: Row }>,
};

export const Grid: FC<Props & TableOptions<object>> = ({
    columns,
    data,
    className,
    isLoading,
    paginationStatus,
    initialState,
    GridItem,
    fetchData = () => { },
}) => {
    const instance = useTable(
        {
            columns,
            data,
            autoResetPage: true,
            manualPagination: true,
            expandSubRows: false,
            manualSortBy: true,
            disableMultiSort: true,
            disableSortRemove: true,
            initialState: {
                sortBy: initialState?.sortBy,
            },
        },
        useFlexLayout,
        useSortBy,
        useExpanded,
        usePagination,
    );

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        rows,
        state: {
            pageIndex,
            pageSize,
            sortBy,
        },
    } = instance;

    React.useEffect(() => {
        fetchData({ pageIndex, pageSize, sortBy });
    }, [pageIndex, pageSize, sortBy])

    const goToPage = (pageIndex: number) => fetchData({ pageIndex });

    const theme = useTheme();
    const styles = useStyles({ theme });

    return (
        <div
            className={classNames(styles.grid, className, { [styles.gridDisabled]: isLoading })}
            {...getTableProps()}
        >
            <div className={styles.body} {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);

                    const { key } = row.getRowProps();

                    return <GridItem key={key} row={row} />
                })}
            </div>

            <TablePagination
                className={styles.pagination}
                paginationStatus={paginationStatus}
                goToPage={goToPage}
            />
        </div>
    )
}
