import React, { FC } from 'react';
import { useTheme, createUseStyles } from 'react-jss';
import { flex } from '@resources/mixins';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { ThemeProps } from '@styles/theme';
import { useGroupsUIStore, useUserProfileUIStore, useUserUIStore, useUsersGridUIStore } from '@core/useStores';
import { ToastMessage } from '@shared/components/Toast';
import { messages } from '@shared/shared.messages';
import classNames from 'classnames';
import ExportIcon from '@assets/export.svg';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    button: {
        border: 'none',
        background: 'none',
        outline: 'none',
        padding: 0,
        height: 24,
        width: 24,
        ...flex('row', 'center', 'center'),

        '&:disabled': {
            opacity: .3,
        },

        '&:hover': {
            background: theme.colors.icon_background,
            borderRadius: theme.radius / 2,
            cursor: 'pointer',
        },
    },

    buttonExport: {
        width: 'auto',
        height: 'auto',
        fontFamily: theme.font.secondary,
        fontSize: 14,
        lineHeight: 1.4,
        fontWeight: 'normal',
        padding: [8, 8],
        borderRadius: theme.radius / 2,
        background: theme.colors.icon_background,
        color: theme.colors.violet_primary,
        '&:hover': {
            background: theme.colors.transp_violet,
        },
    },

    exportIcon: {
        marginRight: 4,
    },

    loader: {
        color: theme.colors.violet_primary,
        marginRight: 4,
    },
}));

export const UsersExport: FC = () => {
    const theme = useTheme();
    const styles = useStyles({ theme });

    const usersUIStore = useUsersGridUIStore();
    const groupsUIStore = useGroupsUIStore();
    const userProfileUIStore = useUserProfileUIStore();

    const exportUsers = () => {
        toast.success(
            <ToastMessage
                message={messages['export.toast.inProgress']}
                type='success'
            />
        );
        const companyId = userProfileUIStore.userInfo.companyId;
        usersUIStore.exportAllUsers({ extendedData: true, groupId: groupsUIStore.selectedGroup?.id }, companyId);
    }

    return (
        <Observer>
            {() => {
                const {
                    isExporting,
                    canExport,
                } = usersUIStore;

                return (
                    <button
                        onClick={exportUsers}
                        className={classNames(styles.button, styles.buttonExport)}
                        disabled={!canExport}>
                        {isExporting && (
                            <CircularProgress className={styles.loader} color='inherit' size={24} />
                        )}
                        {!isExporting && (
                            <img title={messages['export.exportButton.all']} src={ExportIcon}
                                className={classNames(styles.exportIcon)} />
                        )}
                        {
                            messages['export.exportButton.all']
                        }
                    </button>
                )
            }}
        </Observer>
    )
}
