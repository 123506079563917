import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import { createUseStyles, useTheme } from 'react-jss';
import { getEnv } from 'mobx-state-tree';
import classNames from 'classnames';

import { ThemeProps } from '@styles/theme';
import { IStoresEnv } from '@core/storesEnv';
import { useProceduresUIStore } from '@core/useStores';
import { COLLABORATION_OPTIONS } from 'Procedures/components/ProceduresFiltersModal';
import { OptionType } from '@shared/components/SelectDropdown';
import { FilterChip } from '@shared/components/FilterChip';

import { LAST_LOGIN_START_OPTIONS, LAST_LOGIN_END_OPTIONS } from 'Users/components/UsersFiltersModal';
import { valuesSchema } from 'Procedures/components/ProceduresFiltersModal/valuesSchema';

import { messages } from 'Procedures/procedures.messages';

type FilterEntityProps = {
    id: string | null | boolean,
    name: string,
    type?: string
}

type ProceduresFiltersEnumProps = {
    label: string,
    options: FilterEntityProps[]
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    margin: {},
    text: {
        fontFamily: theme.font.secondary,
        fontSize: 16,
        lineHeight: 1.75,
        color: theme.colors.violet_primary,
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '&$margin': {
            marginBottom: theme.spacing(4),
        },
    },
    clear: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
}));

export const ProceduresFiltersBar: FC = () => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const proceduresGridUIStore = useProceduresUIStore();
    const { filtersOptions } = getEnv<IStoresEnv>(proceduresGridUIStore);

    const onClear = () => {
        proceduresGridUIStore.resetParams();
    }

    const onRemove = (id: string, filterKey: string) => {
        const requestParams: Record<string, any> = proceduresGridUIStore.requestParams.getParams;

        proceduresGridUIStore.setParams({
            page: 0,
            [filterKey]: typeof requestParams[filterKey] === 'string'
                ? ''
                : requestParams[filterKey].filter((value: string) => value !== id),
        });
    }

    const onExecutionsFilterRemove = (filterKey: string) => {
        proceduresGridUIStore.setParams({
            page: 0,
            [filterKey]: undefined,
        });
    }

    const onCollaborationFilterRemove = () => {
        proceduresGridUIStore.setParams({
            page: 0,
            collaboration: null,
        });
    }

    return (
        <Observer>
            {() => {
                const filters: Record<string, any> = proceduresGridUIStore.requestParams.getParams;
                // TODO check methods from store and avoid mapping here
                const originalFilters: Record<string, any> = proceduresGridUIStore.proceduresFilters.getFilterOptions;
                const groupOptions = filtersOptions.groups;

                const PROCEDURES_FILTERS: Record<string, ProceduresFiltersEnumProps> = {
                    devices: {
                        label: messages['proceduresFiltersBar.devices.label'],
                        options: originalFilters.devices.map((device: string) => ({ id: device, name: device })),
                    },
                    modes: {
                        label: messages['proceduresFiltersBar.modes.label'],
                        options: originalFilters.modes.map((mode: string) => ({ id: mode, name: mode })),
                    },
                    types: {
                        label: messages['proceduresFiltersBar.types.label'],
                        options: originalFilters.types.map((type: string) => ({ id: type, name: type })),
                    },
                    groupIds: {
                        label: messages['proceduresFiltersBar.groupIds.label'],
                        options: groupOptions,
                    },
                    collaboration: {
                        label: messages['proceduresFiltersBar.collaboration.label'],
                        options: COLLABORATION_OPTIONS
                            .map((option: { value: boolean | null, label: string }) => ({ id: option.value, name: option.label })),
                    },
                    lastModifiedStart: {
                        label: messages['proceduresFiltersBar.lastModifiedStart.label'],
                        options: LAST_LOGIN_START_OPTIONS
                            .map((option: OptionType) => ({ id: option.value, name: option.label })),
                    },
                    lastModifiedEnd: {
                        label: messages['proceduresFiltersBar.lastModifiedStart.label'],
                        options: LAST_LOGIN_END_OPTIONS
                            .map((option: OptionType) => ({ id: option.value, name: option.label })),
                    },
                };

                const regularFiltersSelected = Object.keys(PROCEDURES_FILTERS)
                    .some(key => Boolean(filters[key]) && filters[key].length > 0);

                const anyFilterSelected = regularFiltersSelected || filters.executionsMin !== undefined
                    || filters.executionsMax !== undefined || filters.collaboration !== null;

                return (
                    <div className={classNames(styles.filters, { [styles.margin]: anyFilterSelected })}>
                        {filters.executionsMin !== undefined && (
                            <FilterChip
                                key={valuesSchema.executionsMin.fieldName}
                                text={messages['proceduresFiltersBar.executionsMin'](filters.executionsMin)}
                                onRemove={() => onExecutionsFilterRemove(valuesSchema.executionsMin.fieldName)}
                            />
                        )}
                        {filters.executionsMax !== undefined && (
                            <FilterChip
                                key={valuesSchema.executionsMax.fieldName}
                                text={messages['proceduresFiltersBar.executionsMax'](filters.executionsMax)}
                                onRemove={() => onExecutionsFilterRemove(valuesSchema.executionsMax.fieldName)}
                            />
                        )}
                        {filters.collaboration !== null && (
                            <FilterChip
                                key={valuesSchema.collaboration.fieldName}
                                text={messages['proceduresFiltersBar.collaboration'](filters.collaboration)}
                                onRemove={onCollaborationFilterRemove}
                            />
                        )}

                        {Object.keys(PROCEDURES_FILTERS).map(key => {
                            if (filters[key] && filters[key].length) {
                                if (typeof filters[key] === 'string') {
                                    const option = PROCEDURES_FILTERS[key].options && PROCEDURES_FILTERS[key].options
                                        .find(option => option.id === filters[key]);

                                    return (
                                        option ? (
                                            <FilterChip
                                                key={option.name}
                                                text={`${PROCEDURES_FILTERS[key].label}: ${option.name}`}
                                                onRemove={() => onRemove(filters[key], key)}
                                            />
                                        ) : null
                                    )
                                }

                                return Array.isArray(filters[key]) && filters[key].map((id: string) => {
                                    const option = PROCEDURES_FILTERS[key].options && PROCEDURES_FILTERS[key].options
                                        .find(option => option.id === id);

                                    return (
                                        option ? (
                                            <FilterChip
                                                key={option.name}
                                                text={`${PROCEDURES_FILTERS[key].label}: ${option.name}`}
                                                onRemove={() => onRemove(id, key)}
                                            />
                                        ) : null
                                    )
                                })
                            }
                        })}
                        {anyFilterSelected && (
                            <p
                                onClick={onClear}
                                className={classNames(styles.text, styles.clear)}
                                data-testid="user-filter-bar-clear-button"
                            >
                                Clear all filters
                            </p>
                        )}
                    </div>
                )
            }}
        </Observer>
    )
}
