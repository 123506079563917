export enum PagesNames {
  Login = 'login',
  Companies = 'companies',
  Users = 'users',
  Procedures = 'procedures',
  Collaboration = 'collaboration',
  Licenses = 'licenses',
  Download = 'download',
  Analytics = 'analytics',
  Profile = 'profile',
}
