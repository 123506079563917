import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type EditUserParams = {
    id: string;
    firstName?: string;
    lastName?: string;
    department?: string;
    roleId?: string;
    groupIds?: string[];
    procedureIds?: string[];
};

export const performEditUser = async (api: IApiStore, params: EndpointCallParams<EditUserParams>) => {
    const { payload, ...rest } = params;
    const { id, ...payloadBody } = payload;
    const req = api
        .put(`uiaggregator-app/api/v1/Users/${id}`, {
            ...rest,
            payload: payloadBody,
        })

    return req;
}
