import React from 'react';
import { Observer } from 'mobx-react';
import { createUseStyles, useTheme } from 'react-jss';
import { ThemeProps } from '@styles/theme';

import { SummaryPanel } from '@shared/components/SummaryPanel';
import {
    useGroupsUIStore, useProceduresUIStore,
    useUserPermissionsUIStore
} from '@core/useStores';

import ProceduresIcon from '@assets/procedures.svg';
import AvgExecutionsIcon from '@assets/avg-executions.svg';
import AvgTimeIcon from '@assets/avg-time.svg';
import {
    PROCEDURES_AVAILABILITY_ALL,
    PROCEDURES_IN_PUBLIC,
    PROCEDURES_NOT_ASSIGNED,
    PROCEDURES_PRIVATE,
    PROCEDURES_IN_GROUP_ID,
} from 'Procedures/domain/constants';
import { messages } from 'Procedures/procedures.messages';

export const AVAILABILITY_LABELS = new Map([
    [PROCEDURES_AVAILABILITY_ALL, messages['proceduresSidebar.button.allProcedures']],
    [PROCEDURES_IN_PUBLIC, messages['proceduresSidebar.button.public']],
    [PROCEDURES_NOT_ASSIGNED, messages['proceduresSidebar.button.unassigned']],
    [PROCEDURES_PRIVATE, messages['proceduresSidebar.button.private']],
]);

const useStyles = createUseStyles((theme: ThemeProps) => ({
    panelWrapper: {
        marginTop: -80,
        marginBottom: theme.spacing(4),
    },
}));

export const ProceduresSummary: React.FC = () => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const groupsUIStore = useGroupsUIStore();
    const proceduresGridUIStore = useProceduresUIStore();
    const userPermissionsUIStore = useUserPermissionsUIStore();

    const renderValue = (value: number | null) => value === null ? '' : value;

    return (
        <Observer>
            {() => {
                const data = proceduresGridUIStore.statistics;
                const preparedData = [
                    {
                        id: 0,
                        title: messages['proceduresSummary.total.title'],
                        text: messages['proceduresSummary.total.text'](renderValue(data.proceduresCount)),
                        icon: ProceduresIcon,
                    },
                    {
                        id: 1,
                        title: messages['proceduresSummary.average.title'],
                        text: messages['proceduresSummary.average.text'](renderValue(data.averageExecutionsCount)),
                        icon: AvgExecutionsIcon,
                    },
                    {
                        id: 2,
                        title: messages['proceduresSummary.spent.title'],
                        text: messages['proceduresSummary.spent.text'](renderValue(data.averageTimeSpentCount)),
                        icon: AvgTimeIcon,
                    },
                ];

                const filters = proceduresGridUIStore.requestParams.getParams;

                const title = filters.inGroup === PROCEDURES_IN_GROUP_ID
                    ? groupsUIStore.selectedGroup?.name || ''
                    : AVAILABILITY_LABELS.get(filters.procedureAvailability || 0) || '';

                return (
                    <div className={styles.panelWrapper}>
                        <SummaryPanel
                            title={title}
                            items={preparedData}
                            isEditable={
                                userPermissionsUIStore.canUpdateGroup &&
                                (filters.inGroup === PROCEDURES_IN_GROUP_ID) && !!title
                            }
                            onEdit={() => groupsUIStore.showEditGroup(filters.inGroupId)}
                        />
                    </div>
                )
            }}
        </Observer>
    )
}
