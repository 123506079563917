import {
    Instance, SnapshotIn, types, flow, getEnv,
    applySnapshot, getSnapshot, SnapshotOut
} from 'mobx-state-tree';

import {
    performGetGroups, performGetGroup, performAddGroup, performEditGroup, performDeleteGroup,
    GetGroupsParams, AddGroupParams, EditGroupParams,
} from '../service';
import { GroupStore } from './Group.store';
import { IStoresEnv } from '@core/storesEnv';
import { performExportAllGroups, performExportGroup } from 'Groups/service/exportGroup';

const SELECTED_GROUP_ID = 'SELECTED_GROUP_ID';

export const GroupsDataStoreInferred = types
    .model('GroupsDataStore', {
        groups: types.array(GroupStore),
        selectedGroup: types.maybeNull(types.safeReference(GroupStore)),
    })
    .actions(self => {
        const { api, sessionStorage } = getEnv<IStoresEnv>(self);

        const load = flow(function* (payload: GetGroupsParams) {
            const result = yield performGetGroups(api, {
                payload,
                errorHandlers: { DEFAULT: '' },
            });

            self.groups = result.data;

            prefillFromStorage();
        });

        const loadGroup = flow(function* (id: string) {
            const result = yield performGetGroup(api, {
                payload: { id },
                errorHandlers: { DEFAULT: '' },
            });

            return result;
        });

        const addGroup = flow(function* (payload: AddGroupParams) {
            const result = yield performAddGroup(api, {
                payload,
                errorHandlers: { DEFAULT: '' },
            });

            return result;
        });

        const editGroup = flow(function* (payload: EditGroupParams) {
            const result = yield performEditGroup(api, {
                payload,
                errorHandlers: { DEFAULT: '' },
            });

            return result;
        });

        const deleteGroup = flow(function* (id: string) {
            const result = yield performDeleteGroup(api, {
                payload: { id },
                errorHandlers: { DEFAULT: '' },
            });

            return result;
        });

        const exportAllGroups = flow(function* (filename: string, payload: any) {
            const result = yield performExportAllGroups(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            if (!(result.data instanceof Blob)) { return; }
            const blob = new Blob([result.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            // return result;
        });

        const exportGroup = flow(function* (payload: any) {
            const result = yield performExportGroup(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            return result;
        });

        const setSelectedGroup = (id: string) => {
            applySnapshot(self, { ...getSnapshot(self), selectedGroup: id });
            sessionStorage.setItem(SELECTED_GROUP_ID, id);
        }

        const unselectGroup = () => {
            applySnapshot(self, { ...getSnapshot(self), selectedGroup: null });
            sessionStorage.removeItem(SELECTED_GROUP_ID);
        }

        const prefillFromStorage = () => {
            const storedGroupData = sessionStorage.getItem(SELECTED_GROUP_ID);

            if (storedGroupData) {
                setSelectedGroup(storedGroupData);
            }
        }

        return {
            load,
            loadGroup,
            addGroup,
            editGroup,
            deleteGroup,
            exportAllGroups,
            exportGroup,
            setSelectedGroup,
            unselectGroup,
        }
    });

type GroupsDataStoreFactoryType = typeof GroupsDataStoreInferred;
interface IGroupsDataStoreFactory extends GroupsDataStoreFactoryType { }
export const GroupsDataStore: IGroupsDataStoreFactory = GroupsDataStoreInferred;
export interface IGroupsDataStore extends Instance<IGroupsDataStoreFactory> { }
export interface IGroupsDataStoreSnapshotIn extends SnapshotIn<IGroupsDataStore> { }
export interface IGroupsDataStoreSnaphotOut extends SnapshotOut<IGroupsDataStore> { }
