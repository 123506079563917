import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    disabled: {},
    appExternal: {},
    secondary: {},
    button: {
        border: '1px solid transparent',
        borderRadius: theme.radius,
        fontFamily: theme.font.secondary,
        fontSize: 20,
        lineHeight: 1.4,
        transition: '0.3s',
        height: 40,
        padding: [0, theme.spacing(6)],
        backgroundColor: theme.colors.violet_primary,
        color: theme.colors.white,
        position: 'relative',

        '&$disabled': {
            backgroundColor: theme.colors.grey_text,
            pointerEvents: 'none',
        },

        '&:hover': {
            backgroundColor: theme.colors.violet_darker,
            cursor: 'pointer',
        },

        '&:active': {
            background: theme.colors.violet_darkest,
            boxShadow: 'none',
            '&:focus': {
                boxShadow: 'none',
                border: '1px solid transparent',
            },
        },

        '&$secondary': {
            color: theme.colors.violet_primary,
            backgroundColor: theme.colors.transp_violet,

            '&$disabled': {
                color: theme.colors.grey_text,
                backgroundColor: theme.colors.grey_light,
                pointerEvents: 'none',
            },

            '&:hover': {
                backgroundColor: `${color(theme.colors.violet_darker).fade(0.84)}`,
                color: theme.colors.violet_darker,
                cursor: 'pointer',
            },

            '&:active': {
                background: `${color(theme.colors.violet_darker).fade(0.76)}`,
                color: theme.colors.violet_darker,
                boxShadow: 'none',
            },
        },

        '&$appExternal': {
            background: theme.colors.blue_primary,
            color: theme.colors.black,
            height: 52,
            padding: [6, 34],

            '&$disabled': {
                color: theme.colors.white,
                opacity: .2,
                pointerEvents: 'none',
            },

            '&:hover': {
                background: theme.colors.blue_neon,
                cursor: 'pointer',
            },

            '&:active': {
                background: theme.colors.blue_neon_luminating,
                boxShadow: 'none',
            },
        },

        '&:focus': {
            boxShadow: `0 0 4px 0 ${theme.colors.blue_neon}`,
            border: `1px solid ${theme.colors.blue_primary}`,
            outline: 'none',
        },
    },

    loaderWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 24,
    },

    loader: {
        color: theme.colors.violet_darkest,
    },
}));
