import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams.ts';
import { IApiStore } from '@core/api/apiStore';
import { CreateProcedurePayload } from 'Procedures/domain/CreateProcedurePayload';

export const performCreateProcedure = async (
    api: IApiStore,
    params: EndpointCallParams<CreateProcedurePayload>,
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const req = api
        .post('uiaggregator-app/api/v1/Procedures', {
            ...rest,
            payload,
        })

    return req;
}
