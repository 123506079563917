import React, { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useTheme } from 'react-jss';
import { toast } from 'react-toastify';
import { Observer } from 'mobx-react';
import classNames from 'classnames';

import { Modal } from '@shared/components/Modal';
import { Button } from '@shared/components/Button';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { SelectOption } from '@shared/components/SelectDropdown/Option';
import { ToastMessage } from '@shared/components/Toast';
import { FormError } from '@shared/components/FormError';

import { validationSchema } from './validationSchema';
import { valuesSchema } from './valuesSchema';

import { messages } from 'Licenses/license.messages';

import { useStyles } from './LicenseModal.styles';
import { useLicensesUIStore } from '@core/useStores';

export const COMMON_SELECT_PROPS = {
    isSearchable: false,
    hideSelectedOptions: false,
    isClearable: false,
    isMulti: false,
    closeMenuOnSelect: true,
    components: { Option: SelectOption },
}

export type LicenseModalProps = {
    isOpen: boolean,
    onRequestClose: () => void,
}

export class LicenseFormValues {
    name = '';
    unityId = '';
}

export const LicenseModal: FC<LicenseModalProps> = ({
    isOpen,
    onRequestClose,
}) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const licensesUIStore = useLicensesUIStore();



    const onSubmit = async (values: LicenseFormValues) => {
        const { name, unityId, ...rest } = values;


        const result = await licensesUIStore.createLicense(unityId, name);

        if (result.success) {

            licensesUIStore.loadLicenses();

            onRequestClose();

            toast.success(
                <ToastMessage
                    message={messages['licenseModal.submit.toast.success']}
                    type='success'
                />
            );
        } else {
            onRequestClose();
            toast.error(
                <ToastMessage
                    message={result.error?.body?.messages[0]}
                    type='error'
                />
            );
        }
    }

    const initialValues = new LicenseFormValues();

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
        >
            <h2 className={styles.h2}>
                {messages['licenseModal.newLicense']}
            </h2>
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize={true}
            >
                {(form: FormikProps<LicenseFormValues>) => {

                    const formErrors: Record<string, any> = form.errors;
                    const errors = Object.keys(formErrors)
                        .map((key: string) => formErrors[key].value ? formErrors[key].value : formErrors[key]);

                    return (
                        <Form className={styles.form} autoComplete='off' noValidate>
                            <Observer>
                                {() => (
                                    <>
                                        {errors?.length > 0 && (
                                            <div className={styles.errors}>
                                                {errors.map((text: string) => (
                                                    <FormError className={styles.error} key={text} text={text} />
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </Observer>

                            <div className={styles.fieldWrapper}>
                                <div className={classNames(styles.field)}>
                                    <FormikTextInput schema={valuesSchema.name} />
                                </div>
                            </div>
                            <div className={styles.fieldWrapper}>
                                <div className={classNames(styles.field)}>
                                    <FormikTextInput schema={valuesSchema.unityId} />
                                </div>
                            </div>


                            <div className={classNames(styles.buttons)}>

                                <div>
                                    <Button
                                        onClick={onRequestClose}
                                        className={styles.button} color="secondary"
                                    >
                                        {messages['licenseModal.buttons.cancel']}
                                    </Button>
                                    <Button
                                        className={styles.button}
                                        type="submit"
                                    >
                                        {messages['licenseModal.buttons.submit']}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}
