/* eslint-disable @typescript-eslint/camelcase */
import {
    COLOR_BLACK,
    COLOR_WHITE,
    COLOR_RED,
    COLOR_GREEN,
    COLOR_YELLOW,
    COLOR_TRANSP_YELLOW_HIGHLIGHT,
    COLOR_VIOLET_DARKEST,
    COLOR_VIOLET_DARKER,
    COLOR_VIOLET_PRIMARY,
    COLOR_DEEP_BLUE,
    COLOR_BLUE_LINK,
    COLOR_BLUE_PRIMARY,
    COLOR_BLUE_NEON,
    COLOR_BLUE_NEON_LUMINATING,
    COLOR_GREY_TEXT,
    COLOR_GREY_DARKER,
    COLOR_GREY_LIGHT,
    COLOR_GREY_BACKGROUND,
    COLOR_TRANSP_VIOLET,
    COLOR_TRANSP_GREY_LIGHT,
    COLOR_GREY_LIGHTER,
    COLOR_ICON_BACKGROUND,
    COLOR_TRANSP_BLUE_DARKER,
    COLOR_TRANSP_BLUE_DISABLED,
    COLOR_TRANSP_BLUE
} from './constants'

const colors = {
    black: COLOR_BLACK,
    white: COLOR_WHITE,
    red: COLOR_RED,
    green: COLOR_GREEN,
    yellow: COLOR_YELLOW,
    transp_yellow_hightlight: COLOR_TRANSP_YELLOW_HIGHLIGHT,
    violet_darkest: COLOR_VIOLET_DARKEST,
    violet_darker: COLOR_VIOLET_DARKER,
    violet_primary: COLOR_VIOLET_PRIMARY,
    deep_blue: COLOR_DEEP_BLUE,
    blue_link: COLOR_BLUE_LINK,
    blue_primary: COLOR_BLUE_PRIMARY,
    blue_neon: COLOR_BLUE_NEON,
    blue_neon_luminating: COLOR_BLUE_NEON_LUMINATING,
    grey_text: COLOR_GREY_TEXT,
    grey_darker: COLOR_GREY_DARKER,
    grey_light: COLOR_GREY_LIGHT,
    grey_background: COLOR_GREY_BACKGROUND,
    transp_violet: COLOR_TRANSP_VIOLET,
    transp_grey_light: COLOR_TRANSP_GREY_LIGHT,
    grey_lighter: COLOR_GREY_LIGHTER,
    icon_background: COLOR_ICON_BACKGROUND,
    transp_blue_darker: COLOR_TRANSP_BLUE_DARKER,
    transp_blue_disabled: COLOR_TRANSP_BLUE_DISABLED,
    transp_blue: COLOR_TRANSP_BLUE,
}

export default colors;
