import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';

import { BaseItem } from '@shared/stores/BaseItem.store';
import {
    performEditCompany,
    performGetCompany,
    performDeleteCompany,
    performGetCompanyStatistics,
} from 'Companies/services';
import { IStoresEnv } from '@core/storesEnv';

export const userInitialState = {
    id: '',
    name: '',
    description: '',
    dataIsolation: false,
    allowStandaloneBuild: false,
    expireAt: '',
    createdAt: '',
    maxGroups: 9999,
    maxProcedures: 9999,
    maxLicensesCreator: 9999,
    maxLicensesPlayer: 9999,
    maxUsers: 9999,
    maxLaunchPasses: 9999,
    launchPassStartDate: '',
    isLaunchPassMonthly: false,
    admins: [],
}

export const CompanyStoreInferred = types
    .model('CompanyStoreInferred', {
        id: types.optional(types.string, ''),
        name: types.optional(types.string, ''),
        description: types.maybeNull(types.string),
        dataIsolation: types.optional(types.boolean, false),
        allowStandaloneBuild: types.maybeNull(types.boolean),
        expireAt: types.maybeNull(types.string),
        createdAt: types.maybeNull(types.string),
        maxGroups: types.optional(types.integer, 9999),
        maxProcedures: types.optional(types.integer, 9999),
        maxLicensesCreator: types.optional(types.integer, 9999),
        maxLicensesPlayer: types.optional(types.integer, 9999),
        maxUsers: types.optional(types.integer, 9999),
        maxLaunchPasses: types.optional(types.integer, 9999),
        launchPassStartDate: types.maybeNull(types.string),
        isLaunchPassMonthly: types.optional(types.boolean, false),
        admins: types.optional(types.array(types.string), []),
    })
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);
        const editCompany = flow(function* (payload: any) {
            if (payload.expireAt) {
                const expireAt = new Date(payload.expireAt);
                expireAt.setUTCHours(23);
                expireAt.setUTCMinutes(59);
                expireAt.setUTCSeconds(59);
                payload.expireAt = expireAt.toUTCString();
            }
            if(payload.launchPassStartDate) {
                const launchPassesStart = new Date(payload.launchPassStartDate);
                launchPassesStart.setUTCHours(0);
                launchPassesStart.setUTCMinutes(0);
                launchPassesStart.setUTCSeconds(0);
                payload.launchPassStartDate = launchPassesStart.toUTCString();
            }
            const result = yield performEditCompany(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                    disableDefaultErrorHandlers: true,
                },
            );

            return result;
        });

        const loadCompany = flow(function* (payload: any) {
            const result = yield performGetCompany(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            return result;
        });
        // TODO to call whenever the details tab for the company is opened
        const loadStatistics = flow(function* (payload: any) {
            const result = yield performGetCompanyStatistics(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            return result;
        });
        const deleteCompany = flow(function* (payload: any) {
            const result = yield performDeleteCompany(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            return result;
        });

        return {
            editCompany,
            loadCompany,
            deleteCompany,
            loadStatistics,
        }
    })

type CompanyStoreFactoryType = typeof CompanyStoreInferred;
interface ICompanyStoreFactory extends CompanyStoreFactoryType { }
export const CompanyStore: ICompanyStoreFactory = CompanyStoreInferred;
export interface ICompanyStore extends Instance<ICompanyStoreFactory> { }
export interface ICompanyStoreSnapshotIn extends SnapshotIn<ICompanyStore> { }
