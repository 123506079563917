import { Instance, SnapshotIn, getSnapshot, types, flow, getEnv } from 'mobx-state-tree';
import { getOptionsByNameId } from '@shared/helpers/form';

import { CompanyStore } from './Company.store';
import { IStoresEnv } from '@core/storesEnv';
import { BaseItem } from '@shared/stores/BaseItem.store';
import moment from 'moment';
import { performGetAdminsList } from 'Companies/services/getCompaniesFiltersOptions';

export const CompanyUIStoreInferred = types
    .model('CompanyUIStoreInferred', {
        _company: types.optional(CompanyStore, { dataIsolation: false }),
        isLoading: types.optional(types.boolean, true),
        companyToEditId: types.maybeNull(types.string),
        switchCompanyId: types.maybeNull(types.string),
        companyModalOpened: types.optional(types.boolean, false),
        confirmPasswordModalOpened: types.optional(types.boolean, false),
        deleteConfirmationModalOpened: types.optional(types.boolean, false),
        admins: types.array(BaseItem),
        errors: types.maybeNull(types.array(types.string)),
    })
    .views(self => ({

        get company() { return self._company; },
        get companyUIData() { return getSnapshot(self) },
        get getAdminsOptions() {
            return getOptionsByNameId(self.admins);
        },
        get canExitCompany() {
            const { api } = getEnv<IStoresEnv>(self);
            const { auth } = getEnv(api);

            return auth.userProfile.companyId !== '00000000-0000-0000-0000-000000000000';
        },
    }))
    .actions(self => {
        const editCompany = flow(function* (payload) {
            const result = yield self._company.editCompany(payload);

            if (!result.success) {
                const { body } = result.error || {};

                self.errors = body?.errors;
            } else {
                self.errors = null;
            }

            return result;
        });

        const loadCompany = flow(function* (payload) {
            return yield self._company.loadCompany(payload);

        });


        const { api } = getEnv<IStoresEnv>(self);
        const { auth } = getEnv(api);

        const switchCompany = flow(function* (payload) {
            const result = yield auth.switchCompany(payload);
            if (result.success) {
                const { token, company, user, permissions, roles } = result.data.body || {};
                const keepToken = false;

                if (!token) {
                    return false;
                }

                auth.clearLocalData();

                auth.saveUserInfo({
                    profile: { ...user },
                    company: company,
                    permissions,
                    roles,
                    token: token && {
                        ...token,
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        expires_at: moment().unix() + token.expires_in,
                    },
                }, keepToken);

            } else {
                const { body } = result.error || {};

                self.errors = body?.errors;
            }

            return result.success;
        });

        const deleteCompany = flow(function* (payload) {
            return yield self._company.deleteCompany(payload);
        });
        const loadCompanyStatistics = flow(function* (payload) {
            return yield self._company.loadStatistics(payload);
        });

        const clearErrors = () => {
            self.errors = null;
        }

        const toggleCompanyModalOpen = (open: boolean) => {
            self.companyModalOpened = open;
        };

        const toggleConfirmSwithModalOpen = (open: boolean) => {
            self.confirmPasswordModalOpened = open;
        };

        const toggleDeleteConfirmationModalOpened = (open: boolean) => {
            self.deleteConfirmationModalOpened = open;
        };
        const loadAdmins = flow(function* () {
            const result = yield performGetAdminsList(
                api,
                {
                    payload: {},
                    errorHandlers: { DEFAULT: '' },
                },
            );
            if (result.data) {
                self.admins = result.data;
            }
        });
        const setEditCompanyId = (id: string | null | undefined) => {
            if (id) {
                self.companyToEditId = id;

            }
        }

        const setSwitchCompanyId = (id: string | null) => {
            self.switchCompanyId = id || '00000000-0000-0000-0000-000000000000';
        }

        const clearEditCompanyId = () => self.companyToEditId = null;


        return {
            loadCompany,
            editCompany,
            deleteCompany,
            clearErrors,
            toggleCompanyModalOpen,
            toggleDeleteConfirmationModalOpened,
            setEditCompanyId,
            setSwitchCompanyId,
            clearEditCompanyId,
            switchCompany,
            toggleConfirmSwithModalOpen,
            loadAdmins,
            loadCompanyStatistics,
        }
    });

type CompanyUIStoreFactoryType = typeof CompanyUIStoreInferred;
interface ICompanyUIStoreFactory extends CompanyUIStoreFactoryType { }
export const CompanyUIStore: ICompanyUIStoreFactory = CompanyUIStoreInferred;
export interface ICompanyUIStore extends Instance<ICompanyUIStoreFactory> { }
export interface ICompanyUIStoreSnapshotIn extends SnapshotIn<ICompanyUIStore> { }
