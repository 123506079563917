import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export type ExportUserAnalyticsParams = {
  id: string;
  startDate: string | undefined;
  endDate: string | undefined;
}

export const performExportUserAnalytics
  = async (api: IApiStore, params: EndpointCallParams<ExportUserAnalyticsParams>) => {
    const { payload, ...rest } = params;
    const req = api
      .getBlob(`export-app/api/v1/Analytic/users/${payload.id}
              ?startDate=${payload.startDate}&endDate=${payload.endDate}`, {
        payload: '',
        ...rest,
      });

    return req;
  }