import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export const performGetProcedureVersions = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const { payload, ...rest } = params;
    const req = api
        .get(`content-app/api/v1/Procedures/${payload.procedureId}/ProcedureVersions`, {
            payload: '',
            ...rest,
        })

    return req;
}

export const performGetProcedureVersionPlatforms = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const { payload, ...rest } = params;
    const req = api
        .get(`content-app/api/v1/Procedures/${payload.procedureId}/ProcedureVersionPlatforms`, {
            payload: '',
            ...rest,
        })

    return req;
}
