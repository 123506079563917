import React from 'react';
import { Observer } from 'mobx-react';
import { createUseStyles, useTheme } from 'react-jss';
import { ThemeProps } from '@styles/theme';

import { SummaryPanel } from '@shared/components/SummaryPanel';
import { useGroupsUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { USERS_IN_GROUP_ID, USERS_WITHOUT_GROUP_ID } from '@shared/constants';

import UsersIcon from '@assets/users.svg';
import InstructorsIcon from '@assets/instructors.svg';
import ProceduresIcon from '@assets/procedures.svg';

import { messages } from '@shared/components/components.messages';
import { IUsersStatisticsStore, IBasePageQueryStore } from '@shared/stores';

const ALL_USERS = messages['usersSummary.allUsers'];
const USERS_WITHOUT_GROUP = messages['usersSummary.usersWithoutGroup'];

const useStyles = createUseStyles((theme: ThemeProps) => ({
    panelWrapper: {
        marginTop: -80,
        marginBottom: theme.spacing(4),
    },
}));

type UsersSummaryProps = {
    statistics: IUsersStatisticsStore,
    pageQueryStore: IBasePageQueryStore,
};

export const UsersSummary: React.FC<UsersSummaryProps> = ({ statistics, pageQueryStore }) => {
    const theme = useTheme();
    const styles = useStyles(theme);
    const groupsUIStore = useGroupsUIStore();
    const userPermissionsUIStore = useUserPermissionsUIStore();

    const renderValue = (value: number | null) => value === null ? '' : value;

    return (
        <Observer>
            {() => {
                const filters = pageQueryStore.getParams;
                const preparedData = [
                    {
                        id: 0,
                        title: messages['usersSummary.active.title'],
                        text: messages['usersSummary.active.text'](renderValue(statistics.usersCount)),
                        icon: UsersIcon,
                    },
                    {
                        id: 1,
                        title: messages['usersSummary.managed.title'],
                        text: messages['usersSummary.managed.text'](renderValue(statistics.instructorsCount)),
                        icon: InstructorsIcon,
                    },
                    {
                        id: 2,
                        title: messages['usersSummary.access.title'],
                        text: messages['usersSummary.access.text'](renderValue(statistics.proceduresCount)),
                        icon: ProceduresIcon,
                    },
                ];

                const title = filters.inGroup === USERS_IN_GROUP_ID
                    ? groupsUIStore.selectedGroup?.name || ''
                    : filters.inGroup === USERS_WITHOUT_GROUP_ID
                        ? USERS_WITHOUT_GROUP
                        : ALL_USERS;

                return (
                    <div className={styles.panelWrapper}>
                        <SummaryPanel
                            title={title}
                            items={preparedData}
                            isEditable={
                                userPermissionsUIStore.canUpdateGroup &&
                                (filters.inGroup === USERS_IN_GROUP_ID) && !!groupsUIStore.selectedGroup?.id
                            }
                            onEdit={() => groupsUIStore.showEditGroup(filters.inGroupId)}
                        />
                    </div>
                )
            }}
        </Observer>
    )
}
