import React, { FC } from 'react';
import classNames from 'classnames';
import { toast, ToastContainer as ToastContainerToastify } from 'react-toastify';
import { createUseStyles, useTheme } from 'react-jss';
import { ThemeProps } from '@styles/theme';

type ToastContainerProps = {
    className?: string,
    bodyClassName?: string,
    toastClassName?: string,
    style?: object
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    '@global': {
        '.Toastify__toast--success': {
            backgroundColor: theme.colors.green,
        },
        '.Toastify__toast--error': {
            backgroundColor: theme.colors.red,
        },
    },
    body: {
        fontFamily: theme.font.secondary,
        fontSize: 16,
        lineHeight: 1.5,
        color: theme.colors.white,
    },
    wrapper: {
        bottom: 0,
        right: theme.spacing(4),
        width: 470,
        maxWidth: 470,
    },
    toast: {
        minHeight: 48,
        borderRadius: theme.radius,
        marginBottom: theme.spacing(4),
        padding: theme.spacing(3),
    },
}))

export const ToastContainer: FC<ToastContainerProps> = props => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const { bodyClassName, toastClassName, className, ...rest } = props;

    return (
        <ToastContainerToastify
            position={toast.POSITION.BOTTOM_RIGHT}
            bodyClassName={classNames(styles.body, bodyClassName)}
            toastClassName={classNames(styles.toast, toastClassName)}
            className={classNames(styles.wrapper, className)}
            hideProgressBar={true}
            autoClose={5000}
            closeButton={false}
            {...rest}
        />
    )
}
