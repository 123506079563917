import { FormSchema } from '@shared/components/forms/FormSchema';

import { UserInfoFormValues } from './UserInfoForm';
import { messages } from '../../profile.messages';

type UserInfoFormSchema = FormSchema<UserInfoFormValues>;

export const FIELD_NAMES = {
    email: messages['profileForm.email.label'],
    firstName: messages['profileForm.firstName.label'],
    lastName: messages['profileForm.lastName.label'],
}

export const valuesSchema: UserInfoFormSchema = {
    email: {
        fieldName: 'email',
        label: `${FIELD_NAMES.email}`,
        isRequired: true,
        type: 'email',
    },

    firstName: {
        fieldName: 'firstName',
        label: `${FIELD_NAMES.firstName}`,
        isRequired: true,
        type: 'text',
    },

    lastName: {
        fieldName: 'lastName',
        label: `${FIELD_NAMES.lastName}`,
        isRequired: true,
        type: 'text',
    },
};
