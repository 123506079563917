import { createBrowserHistory, History } from 'history';
import { Provider } from 'mobx-react';
import ReactDOM from 'react-dom';
import React from 'react';
import { ThemeProvider } from 'react-jss';
import jssTheme from '@styles/theme';

import { App } from './App';
import { initializeDependenciesAndCreateStores, Stores } from './createStores';

const rootHtmlElement = document.getElementById('root');

const renderApp = (stores: Stores, history: History) => (
    ReactDOM.render(
        <Provider {...stores}>
            <ThemeProvider theme={jssTheme}>
                <App history={history} />
            </ThemeProvider>
        </Provider>,
        rootHtmlElement,
    )
);

const renderAppBootstrapError = () => (
    ReactDOM.render(
        <div>ERROR - app bootsrap failed</div>,
        rootHtmlElement,
    )
);

(async () => {
    const history = createBrowserHistory({ basename: '/' });

    const storesOuterDependencies = { history };

    try {
        const initialStores = await initializeDependenciesAndCreateStores(storesOuterDependencies);

        renderApp(initialStores, history);
    } catch (e) {
        renderAppBootstrapError();
    }
})();

if (module.hot) {
    module.hot.accept();
}
