import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';


type ExportGroupParams = {
    id: string;
    extendedData: boolean;
}


export const performExportAllGroups = async (api: IApiStore, params: EndpointCallParams<ExportGroupParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .getBlob(`export-app/api/v1/Groups/all`, {
            ...rest,
            payload,
        });

    return req;
}

export const performExportGroup = async (api: IApiStore, params: EndpointCallParams<ExportGroupParams>) => {
    const { payload, ...rest } = params;
    const req = api
        .getBlob(`export-app/api/v1/Groups/${payload.id}?extendedData=${payload.extendedData}`, {
            ...rest,
        });

    return req;
}