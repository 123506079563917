import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { DownloadableStore } from './Downloadable.store';

export const DownloadableGroupStoreInferred = types.model('DownloadableGroupStore', {
    id: types.identifier,
    name: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    downloadables: types.optional(types.array(DownloadableStore), []),
});

type DownloadableGroupStoreFactoryType = typeof DownloadableGroupStoreInferred;
interface IDownloadableGroupStoreFactory extends DownloadableGroupStoreFactoryType { }
export const DownloadableGroupStore: IDownloadableGroupStoreFactory = DownloadableGroupStoreInferred;
export interface IDownloadableGroupStore extends Instance<IDownloadableGroupStoreFactory> { }
export interface IDownloadableGroupStoreSnapshotIn extends SnapshotIn<IDownloadableGroupStore> { }
