import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

export type GetCompanyStatisticsParams = {
    id: string;
};



export const performGetCompanyStatistics = async (api: IApiStore, params: EndpointCallParams<GetCompanyStatisticsParams>) => {
    const { payload, ...rest } = params;
    const { id, ...restOfPayload } = payload;
    const req = api
        .get(`uiaggregator-app/api/v1/Companies/${id}/Statistics`, {
            ...rest,
            ...restOfPayload,
        })

    return req;
}

