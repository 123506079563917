export const messages = {
    noPermissionsForCurrentUser: 'No permissions for current User.',
    'export.exportButton': 'Export',
    'export.exportButton.all': 'Export All',
    'export.exportButton.selected': 'Export Selected',
    'export.exportButton.analytics': 'Export Analytics',
    'export.exportButton.executions': 'Export Executions',
    'export.toast.inProgress': 'Exporting data, please wait...',

    'import.importButton': 'Import',
    'import.importButton.users': 'Import Users',
    'import.toast.inProgress': 'Importing data, please wait...',
    'import.toast.unsupported': 'Unsupported file type, please upload a .csv file.',
};
