import React, { useEffect } from 'react';
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { useStyles } from './GroupsList.styles';
import { useGroupsUIStore, useUserPermissionsUIStore, useUserProfileUIStore } from '@core/useStores';
import { SearchForm } from '@shared/components/SearchForm';
import { ToastMessage } from '@shared/components/Toast';
import { Button } from '@shared/components/Button';
import { SidebarFilterButton } from '@shared/components/SidebarFilterButton';
import { messages } from 'Groups/groups.messages';
import ExportIcon from '@assets/export.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

export const GroupsList: React.FC = () => {
    const theme = useTheme();
    const styles = useStyles({ theme });
    const groupsUIStore = useGroupsUIStore();
    const params: { id: string } = useParams();
    const history = useHistory();
    const { id } = params;

    useEffect(() => {
        groupsUIStore.loadGroups();
    }, []);

    const userPermissionsUIStore = useUserPermissionsUIStore();

    const onSearchTextChanged = (text: string) => groupsUIStore.search(text);

    const onGroupClick = (groupId: string) => {
        groupsUIStore.setSelectedGroup(groupId);
        if (id) {
            history.push('/analytics');
        }
    }

    // Export stuff
    const userProfileUIStore = useUserProfileUIStore();
    const defaultPayload = {
        extendedData: true,
    }
    const filename = `Groups_${userProfileUIStore.userInfo.companyId}_ex.csv`;
    const onExportButtonClick = () => {
        toast.success(
            <ToastMessage
                message={messages['groupsList.export.toast']}
                type='success'
            />
        );
        groupsUIStore.exportAllGroups(filename, defaultPayload);
    }
    // ------

    return (
        <Observer>
            {() => {
                const {
                    isExporting,
                    canExport,
                } = groupsUIStore;

                return (
                    <section className={styles.panelWrapper}>
                        {
                            !groupsUIStore.hasNoGroups && (
                                <div className={classNames(styles.rowContainer)}>
                                    <SearchForm
                                        initialValues={{
                                            search: groupsUIStore.getStorageSearchText || '',
                                        }}
                                        className={styles.searchPanel}
                                        onSearchTextChanged={onSearchTextChanged}
                                        valueDelay={300}
                                    />

                                    <button
                                        onClick={onExportButtonClick}
                                        className={classNames(styles.button, styles.buttonExport)}
                                        disabled={!canExport}
                                    >
                                        {isExporting && (
                                            <CircularProgress className={styles.loader} color='inherit' size={24} />
                                        )}
                                        {!isExporting && (
                                            <img title={messages['groupsList.export.exportAllGroups']} src={ExportIcon} />
                                        )}
                                    </button>
                                </div>
                            )
                        }

                        {
                            groupsUIStore.groups.map(group =>
                                <SidebarFilterButton
                                    key={group.id}
                                    className={styles.menuItem}
                                    onClick={() => onGroupClick(group.id)}
                                    active={groupsUIStore.isActive(group.id)}
                                >
                                    {group.name}
                                </SidebarFilterButton>
                            )
                        }

                        {
                            groupsUIStore.isSearchApplied && !groupsUIStore.groups.length && (
                                <div
                                    className={styles.emptyListText}
                                    data-testid="no-groups-found"
                                >
                                    {messages['groupsList.noGroups']}
                                </div>
                            )
                        }

                        {
                            userPermissionsUIStore.canCreateGroup && groupsUIStore.hasNoGroups && (
                                <div className={styles.emptyListText}>
                                    <span>{messages['groupsList.noGroupsCreated']}</span>
                                    <Button
                                        className={styles.addNewGroupButton}
                                        onClick={() => groupsUIStore.toggleGroupModalOpen(true)}
                                    >
                                        {messages['groupsList.create']}
                                    </Button>
                                </div>
                            )
                        }
                    </section>
                )
            }
            }
        </Observer>
    )
};
