import React from 'react';
import { Formik } from 'formik';
import { createUseStyles } from 'react-jss';

import { LoginFormControls } from './LoginFormControls';
import { validationSchema } from './validationSchema';
import { FormValues } from './FormValues';
import { useLoginUIStore } from '@core/useStores';

const useStyles = createUseStyles({
    viewWrapper: {
        width: 468,
    },
});

export const LoginForm: React.FC = () => {
    const loginUiStore = useLoginUIStore();
    const styles = useStyles();

    const loginUser = (values: FormValues) => {
        loginUiStore.login(values.email, values.password);
    }

    return (
        <div className={styles.viewWrapper}>
            <Formik
                onSubmit={loginUser}
                initialValues={new FormValues()}
                enableReinitialize={true}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {props => <LoginFormControls {...props} />}
            </Formik>
        </div>
    )
}
