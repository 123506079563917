import { createUseStyles } from 'react-jss';
import { ThemeProps } from '@styles/theme';

import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    expanded: {},
    notActive: {},
    body: {
        display: 'flex',
        padding: [theme.spacing(4), theme.spacing(4), theme.spacing(4), theme.spacing(10)],
    },
    noInfo: {
        color: theme.colors.grey_text,
    },
    tableActions: {
        ...flex('row', 'space-between', 'center'),
        padding: [0, theme.spacing(4)],
        marginBottom: theme.spacing(4),
    },
    filters: {
        display: 'flex',
        alignItems: 'center',
    },
    invite: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        border: 'none',
        background: 'none',
        outline: 'none',
        padding: 0,
        height: 24,
        width: 24,

        marginLeft: theme.spacing(3),
        '&:disabled': {
            opacity: .3,
        },

        '&:hover': {
            background: theme.colors.icon_background,
            borderRadius: theme.radius / 2,
            cursor: 'pointer',
        },
    },
    h4: {
        fontFamily: theme.font.secondary,
        fontSize: 20,
        lineHeight: 1.4,
        fontWeight: 'normal',
        color: theme.colors.black,
    },
    searchWrapper: {
        width: 312,
        marginRight: -theme.spacing(4),
    },
    nameWrapper: {
        ...flex('row', 'center', 'center'),
    },
    expandIcon: {
        transformOrigin: 'center',
        transition: 'transform .2s ease',
        transform: 'rotate(90deg)',

        '&$expanded': {
            transform: 'rotate(-90deg)',
        },
    },
    licenseName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    bodySubComponent: {
        display: 'flex',
        flexDirection: 'column',
        padding: [theme.spacing(4), theme.spacing(4), theme.spacing(4), theme.spacing(10)],
    },
    descriptionWrapper: {
        padding: [0, theme.spacing(2), theme.spacing(4), theme.spacing(2)],
        flex: '18 0 18%',

    },
    header: {
        color: theme.colors.grey_text,
        fontSize: 16,
        fontFamily: theme.font.secondary,
        lineHeight: 1.25,
        paddingBottom: 10,
    },
    text: {
        color: theme.colors.black,
        lineHeight: 1.5,
        fontSize: 16,
        fontFamily: theme.font.primary,
    },
    placeholder: {
        width: '35px',
        display: 'inline-block',
    },
    addIcon: {
        color: theme.colors.violet_primary,
        cursor: 'pointer',
        marginLeft: theme.spacing(3),
    },
}));
