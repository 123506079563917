import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import { getEnv } from 'mobx-state-tree';
import { useTheme, createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { ALL_USERS_ID, USERS_WITHOUT_GROUP_ID } from '@shared/constants';
import { SidebarFilterButton } from '@shared/components/SidebarFilterButton';
import { useAnalyticsGridUIStore, useUserPermissionsUIStore } from '@core/useStores';;
import { IStoresEnv } from '@core/storesEnv';
import { RoutePaths } from '@core/routes/RoutePaths';
import { messages } from 'Analytics/analytic.messages';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    wrapper: {
        marginBottom: theme.spacing(4),
    },

    menuItem: {
        marginBottom: theme.spacing(2),
    },
}));

export const AnalyticsSidebar: FC = () => {
    const theme = useTheme();
    const styles = useStyles({ theme });

    const analyticsGridUIStore = useAnalyticsGridUIStore();
    const userPermissionsUIStore = useUserPermissionsUIStore();

    const { groups, navigator } = getEnv<IStoresEnv>(analyticsGridUIStore);

    const setGroupFilter = (inGroup: number) => {
        navigator.to(RoutePaths.analytics);

        analyticsGridUIStore.setParams({
            page: 0,
            inGroup,
            inGroupId: undefined,
        });

        groups.unselectGroup();
    }

    return (
        <Observer>
            {() => {
                const filters = analyticsGridUIStore.requestParams.getParams;

                return (
                    <div className={styles.wrapper}>
                        <SidebarFilterButton
                            className={styles.menuItem}
                            active={filters.inGroup === ALL_USERS_ID}
                            onClick={() => setGroupFilter(ALL_USERS_ID)}
                        >
                            {messages['analyticsSidebar.allUsers']}
                        </SidebarFilterButton>

                        {
                            !userPermissionsUIStore.isPlayer && (
                                <SidebarFilterButton
                                    className={styles.menuItem}
                                    active={filters.inGroup === USERS_WITHOUT_GROUP_ID}
                                    onClick={() => setGroupFilter(USERS_WITHOUT_GROUP_ID)}
                                >
                                    {messages['analyticsSidebar.usersWithoutGroups']}
                                </SidebarFilterButton>
                            )
                        }
                    </div>
                )
            }}
        </Observer>
    )
}
