import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams';
import { IApiStore } from '@core/api/apiStore';

export type ExportProcedureParams = {
    id: string;
    groupId: string;
    extendedData: boolean;
}

export const performRequestOtp = async (
    api: IApiStore,
    params: EndpointCallParams<ExportProcedureParams>,
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const req = api
        .post('identity-app/api/v1/external/GenerateOTP', {
            ...rest,
            payload,
        })

    return req;
}

export const performRefreshOtp = async (
    api: IApiStore,
    url: string,
): Promise<ApiResponse> => {
    const req = api
        .post(url, {
            payload: null,
            errorHandlers: { DEFAULT: '' },
            disableDefaultErrorHandlers: true,
        }, undefined, true);

    return req;
}

export const performValidateOtp = async (
    api: IApiStore,
    otp: string,
): Promise<ApiResponse> => {
    const req = api
        .post(`identity-app/api/v1/external/ValidateOTP?otp=${otp}`, {
            payload: null,
            errorHandlers: { DEFAULT: '' },
            disableDefaultErrorHandlers: true,
        });

    return req;
}
