import { ApiResponse } from '@ace/core';

import { EndpointCallParams } from '@core/api/endpointCallParams.ts';
import { IApiStore } from '@core/api/apiStore';

export type UploadFileParams = {
    file: File;
    token: string;
    procedureId: string;
    deviceId: string;
};

export const performUploadFile = async (
    api: IApiStore,
    params: EndpointCallParams<UploadFileParams>,
    progressHandler?: (percent: number) => void,
): Promise<ApiResponse> => {
    const { payload, ...rest } = params;
    const { file, ...uploadMetaData } = payload;

    const req = api
        .postAFile(
            'contentfileprovider-app/api/v1/FileProvider',
            {
                ...rest,
                payload,
                progressHandler,
            },
            { fileField: 'archive', file },
            { ...uploadMetaData },
        );

    return req;
}
