import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';
import { flex, stickyFormBlock } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    stepNameActive: {},

    modal: {
        width: 640,
        maxHeight: 'calc(100vh - 50px)',
        overflowY: 'auto',
    },

    modalHeading: {
        ...flex('row', 'space-between', 'center'),
        marginBottom: theme.spacing(7),
    },

    centeredDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    loader: {
        color: theme.colors.violet_primary,
    },

    h2: {
        fontFamily: theme.font.secondary,
        fontSize: 24,
        lineHeight: 1.33,
        color: theme.colors.black,
        fontWeight: 'normal',
    },

    otp: {
        fontFamily: theme.font.secondary,
        fontSize: 32,
        lineHeight: 1.33,
        color: theme.colors.violet_primary,
        fontWeight: 'bolder',
        alignSelf: 'center',
        marginBottom: theme.spacing(4),
    },

    instructions: {
        marginBottom: theme.spacing(2),
    },

    expiration: {
        fontFamily: theme.font.primary,
        fontSize: 20,
    },

    validationFailed: {
        ...flex('row', '', 'center'),
        marginBottom: theme.spacing(3),
        color: theme.colors.grey_text,
        fontSize: 16,
    },

    failIcon: {
        marginRight: theme.spacing(3),
        color: theme.colors.red,
    },

    field: {
        marginBottom: theme.spacing(6),
    },

    fieldWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },

    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(4),
    },

    button: {
        marginLeft: theme.spacing(5),
    },

    errors: {
        ...stickyFormBlock(),
    },

    error: {
        backgroundColor: theme.colors.red,
        marginBottom: theme.spacing(2),
    },

    hidden: {
        display: 'none',
    },

    longDash: {
        display: 'inline-block',
        width: 20,
        height: 2,
        backgroundColor: theme.colors.grey_light,
        margin: [0, theme.spacing(4)],
    },

    confirmationPanel: {
        paddingTop: theme.spacing(4),
    },

    confirmationPanelText: {
        fontSize: 20,
        lineHeight: 1.4,
        color: theme.colors.black,
        marginBottom: theme.spacing(6),
    },

    confirmationPanelActions: {
        ...flex('row', 'flex-end', 'center'),

        '& button': {
            marginLeft: theme.spacing(5),
        },
    },
}));
