import { SPACING_BASE, RADIUS, FONT_PRIMARY, FONT_SECONDARY } from './constants';
import colors from './colors'

export type ThemeProps = {
  colors: {
    black: string,
    white: string,
    red: string,
    green: string,
    yellow: string,
    transp_yellow_hightlight: string,
    violet_darkest: string,
    violet_darker: string,
    violet_primary: string,
    deep_blue: string,
    blue_link: string,
    blue_primary: string,
    blue_neon: string,
    blue_neon_luminating: string,
    grey_text: string,
    grey_darker: string,
    grey_light: string,
    grey_background: string,
    transp_violet: string,
    transp_grey_light: string,
    grey_lighter: string,
    icon_background: string,
    transp_blue_darker: string,
    transp_blue_disabled: string,
    transp_blue: string,
  },
  font: {
    primary: string,
    secondary: string,
  },
  spacing: Function,
  radius: number
}

const jssTheme = {
    colors,
    spacing: (multiplier: number) => SPACING_BASE * multiplier,
    radius: RADIUS,
    font: {
        primary: FONT_PRIMARY,
        secondary: FONT_SECONDARY,
    },
}

export default jssTheme;
