import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Observer } from 'mobx-react';
import classNames from 'classnames';
import { Formik, Form, FormikProps } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Brush
} from 'recharts';
import { useTheme } from 'react-jss';
import { AutoSubmit } from '@shared/components/SearchForm/AutoSubmit';
import { SelectOption } from '@shared/components/SelectDropdown/Option';
import { FormikSelectDropdown } from '@shared/components/forms/formikWrappers/FormikSelectDropdown';
import { FormSchema } from '@shared/components/forms/FormSchema';
import { FormError } from '@shared/components/FormError';
import { ISelectOption } from '@shared/helpers/form/ISelectOption';
import { getOptionByString } from '@shared/helpers/form';
import { useUserAnalyticUIStore } from '@core/useStores';
import {
    COLOR_GREY_TEXT,
    COLOR_TRANSP_GREY_LIGHT,
    COLOR_GREY_LIGHT,
    COLOR_YELLOW
} from '@styles/constants';
import { useStyles } from './AnalyticsLineChart.styles';
import { formatSecondsToTimeString } from '@shared/helpers/timeFormatters';
import { messages } from 'Analytics/analytic.messages';
import { CustomizedAxisTick } from './CustomizedAxisTick';
import { CustomizedTooltip } from './CustomizedTooltip';

const EMPTY_OPTION = { value: '', label: '' } as ISelectOption;
const NULL_STEP = { time: 0, date: new Date() }
const START_STEP = {
    name: messages['lineChart.start'],
    allAverage: NULL_STEP,
    usrBest: NULL_STEP,
    usr1: NULL_STEP,
    usr2: NULL_STEP,
    usr3: NULL_STEP,
}

const FINISH_STEP = {
    ...START_STEP, name: messages['lineChart.finish'],
}

class FormValues {
    procedureVersionId = EMPTY_OPTION;
    executionMode = EMPTY_OPTION;
    procedureVersionPlatformId = EMPTY_OPTION;
}

type FilterFormSchema = FormSchema<FormValues>;

const valueSchema: FilterFormSchema = {
    procedureVersionId: {
        fieldName: 'procedureVersionId',
        placeholder: '',
    },
    executionMode: {
        fieldName: 'executionMode',
        placeholder: '',
    },
    procedureVersionPlatformId: {
        fieldName: 'procedureVersionPlatformId',
        placeholder: '',
    },
}

const COMMON_SELECT_PROPS = {
    isSearchable: false,
    hideSelectedOptions: false,
    isClearable: false,
    isMulti: false,
    closeMenuOnSelect: true,
    components: { Option: SelectOption },
}
const BRUSH_LIMIT = 20;

const yAxisTickFormatter = (value: number) => Math.trunc(value / 60);

export const AnalyticsLineChart: FC = () => {
    const userAnalyticUIStore = useUserAnalyticUIStore();
    const params: { id: string } = useParams();

    const onSubmit = (values: FormValues) => {
        userAnalyticUIStore.setParams({
            userId: params.id,
            procedureVersionId: values.procedureVersionId?.value,
            executionMode: values.executionMode?.value,
            procedureVersionPlatformId: values.procedureVersionPlatformId?.value,
        })
    }

    useEffect(() => {
        userAnalyticUIStore.resetParams();
        userAnalyticUIStore.setParams({
            userId: params.id,
        });
    }, [params.id])

    return (
        <Observer>
            {() => {
                const {
                    procedureSteps,
                    allUsersAverage,
                    bestTime,
                    requestParams: {
                        procedureId,
                        procedureVersionId,
                        executionMode,
                        procedureVersionPlatformId,
                    },
                    isStepsLoading,
                    analyticsFilters: {
                        getProcedureVersionsOptions,
                        getModeOptions,
                        getProcedureVersionPlatformsOptions,
                    },
                    procedureStepsError,
                    selectedProcedureName,
                } = userAnalyticUIStore;

                const showBrush = procedureSteps && procedureSteps.length > BRUSH_LIMIT;

                const theme = useTheme();
                const styles = useStyles({ theme, showBrush });
                const tickWidth = (100 / (procedureSteps ? procedureSteps.length + 2 : 100));

                const filtersAreSelected = !!procedureVersionId && !!procedureVersionPlatformId;

                const initialValues = {
                    procedureVersionId: procedureVersionId
                        ? getOptionByString(procedureVersionId, getProcedureVersionsOptions)
                        : EMPTY_OPTION,
                    executionMode: executionMode
                        ? getOptionByString(executionMode, getModeOptions(procedureVersionId))
                        : EMPTY_OPTION,
                    procedureVersionPlatformId: procedureVersionPlatformId
                        ? getOptionByString(procedureVersionPlatformId, getProcedureVersionPlatformsOptions(procedureVersionId))
                        : EMPTY_OPTION,
                }

                if (!procedureId) {
                    return <></>;
                }

                return (
                    <div className={
                        classNames(styles.chart, { [styles.noData]: !procedureSteps })
                    }>
                        <div className={styles.header}>
                            <div className={styles.info}>
                                <Typography className={styles.title} variant="h6">{selectedProcedureName}</Typography>
                                <div className={styles.summary}>
                                    <div className={styles.chip}>
                                        {messages['lineChart.bestTime'](formatSecondsToTimeString(bestTime))}
                                    </div>
                                    <div className={classNames(styles.chip, styles.average)}>
                                        {messages['lineChart.averageTime'](formatSecondsToTimeString(allUsersAverage))}
                                    </div>
                                </div>
                            </div>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                            >
                                {({ values, submitForm }: FormikProps<FormValues>) => {
                                    return (
                                        <>
                                            <Form className={styles.filters} autoComplete='off' noValidate>
                                                <div className={styles.filter}>
                                                    <div className={styles.filterSubElement}>
                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            className={styles.h5}
                                                        >
                                                            {messages['lineChart.filter.version']}
                                                        </Typography>
                                                        <FormikSelectDropdown
                                                            schema={valueSchema.procedureVersionId}
                                                            className={styles.dropdown}
                                                            {...COMMON_SELECT_PROPS}
                                                            options={getProcedureVersionsOptions}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.filter}>
                                                    <div className={styles.filterSubElement}>
                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            className={styles.h5}
                                                        >
                                                            {messages['lineChart.filter.mode']}
                                                        </Typography>

                                                        <FormikSelectDropdown
                                                            schema={valueSchema.executionMode}
                                                            className={styles.dropdown}
                                                            disabled={!procedureVersionId || procedureVersionId.length == 0}
                                                            {...COMMON_SELECT_PROPS}
                                                            options={getModeOptions(procedureVersionId)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.filter}>
                                                    <div className={styles.filterSubElement}>
                                                        <Typography
                                                            variant="body1"
                                                            color="secondary"
                                                            className={styles.h5}
                                                        >
                                                            {messages['lineChart.filter.platform']}
                                                        </Typography>

                                                        <FormikSelectDropdown
                                                            schema={valueSchema.procedureVersionPlatformId}
                                                            className={styles.dropdown}
                                                            disabled={!procedureVersionId || procedureVersionId.length == 0}
                                                            {...COMMON_SELECT_PROPS}
                                                            options={getProcedureVersionPlatformsOptions(procedureVersionId)}
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                            <AutoSubmit values={values} submitForm={submitForm} />
                                        </>
                                    )
                                }}
                            </Formik>
                        </div>
                        {procedureStepsError && (
                            <FormError text={procedureStepsError} />
                        )}
                        {isStepsLoading && (
                            <CircularProgress className={styles.loader} color='inherit' size={24} />
                        )}
                        {(filtersAreSelected && procedureSteps && procedureSteps.length > 0) && (
                            <>
                                <ResponsiveContainer width="100%" height={260}>
                                    <LineChart
                                        data={[START_STEP, ...procedureSteps, FINISH_STEP]}
                                        height={260}
                                        margin={{ top: 25, right: 20, left: -38, bottom: 5 }}
                                    >
                                        <XAxis
                                            width={1000}
                                            tick={<CustomizedAxisTick />}
                                            interval={showBrush ? 'preserveStartEnd' : 0}
                                            dataKey="name"
                                        />
                                        <YAxis
                                            tickFormatter={yAxisTickFormatter}
                                            tick={{ fill: COLOR_GREY_TEXT, fontSize: 10 }}
                                            label={{
                                                value: messages['lineChart.YAxis.label'],
                                                position: 'top',
                                                offset: 0,
                                                className: styles.yAxisLabel,
                                            }}
                                        />
                                        <CartesianGrid stroke={COLOR_TRANSP_GREY_LIGHT} horizontal={false} />
                                        <Tooltip content={<CustomizedTooltip />} />
                                        <Line dot={false} type="monotone" dataKey="usr1.time" stroke={COLOR_GREY_LIGHT} />
                                        <Line dot={false} type="monotone" dataKey="usr2.time" stroke={COLOR_GREY_LIGHT} />
                                        <Line dot={false} type="monotone" dataKey="usr3.time" stroke={COLOR_GREY_LIGHT} />
                                        <Line dot={false} type="monotone" dataKey="usrBest.time" stroke={COLOR_YELLOW} />
                                        <Line dot={false} type="monotone" dataKey="allAverage.time" stroke="#13c2c2" />
                                        {/** TODO: use theme color */}
                                        {showBrush && (
                                            <Brush
                                                className={styles.brush}
                                                dataKey='name'
                                                height={30}
                                                stroke="#13c2c2"
                                            />
                                        )}
                                    </LineChart>
                                </ResponsiveContainer>
                                {showBrush && (
                                    <div className={styles.brushTicks}>
                                        <div
                                            key="startTick"
                                            className={styles.tick}
                                            style={{
                                                width: `${tickWidth}%`,
                                            }}
                                        >
                                            <span className={styles.startTick}>
                                                {messages['lineChart.start']}
                                            </span>
                                        </div>
                                        {[START_STEP, ...procedureSteps, FINISH_STEP].map((item, i) => (
                                            <div
                                                key={`${item.name}-${i}`}
                                                className={styles.tick}
                                                style={{
                                                    width: `${tickWidth}%`,
                                                }}
                                            >
                                                <div className={styles.index}>
                                                    {i + 1}
                                                </div>
                                            </div>
                                        ))}
                                        <div
                                            key="finishTick"
                                            className={styles.tick}
                                            style={{
                                                width: `${tickWidth}%`,
                                            }}
                                        >
                                            <span className={styles.finishTick}>
                                                {messages['lineChart.finish']}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )
            }}
        </Observer>
    )
}
