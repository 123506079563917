import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { BaseItem } from '@shared/stores/BaseItem.store';

const ProcedureGeneralInfoInferred = types
    .model('ProcedureGeneralInfoInferred', {
        id: types.maybe(types.string),
        name: types.maybe(types.string),
        description: types.maybe(types.string),
        userIds: types.array(types.string),
        users: types.array(BaseItem),
        groupIds: types.array(types.string),
        groups: types.array(BaseItem),
        ownership: types.maybe(types.string),
    });

type ProcedureGeneralInfoFactoryType = typeof ProcedureGeneralInfoInferred;
interface IProcedureGeneralInfoFactory extends ProcedureGeneralInfoFactoryType { };
export const ProcedureGeneralInfo: IProcedureGeneralInfoFactory = ProcedureGeneralInfoInferred;
export interface IProcedureGeneralInfo extends Instance<IProcedureGeneralInfoFactory> { };
export interface IProcedureGeneralInfoSnapshotIn extends SnapshotIn<IProcedureGeneralInfo> { };
