import React, { FC, MouseEvent } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import color from 'color';

import { ThemeProps } from '@styles/theme';

type DropdownMenuProps = {
    isOpen: boolean,
    items: Array<MenuItemType>,
    className?: string,
    itemRenderer?: Function,
    onItemClick?: Function
}

type MenuItemType = {
    id: number,
    title: string,
    onClick?: Function
}

type CssClassesType = {
    text: string,
    item: string
}

type DefaultClickHandlerType = {
    item: object,
    event: MouseEvent<HTMLElement>,
    onItemClick?: Function
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    menu: {
        minWidth: 150,
        minHeight: 56,
        padding: [5, 0],
        borderRadius: theme.radius,
        backgroundColor: theme.colors.white,
        border: `2px solid ${color(theme.colors.grey_light).fade(0.86)}`, // TODO: change to constant
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        height: 40,
        backgroundColor: theme.colors.white,
        padding: [0, theme.spacing(5)],
        transition: 'background-color .2s ease',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: color(theme.colors.violet_primary).fade(0.92).string(),
        },
    },
    text: {
        fontFamily: theme.font.primary,
        textAlign: 'left',
        color: theme.colors.black,
    },
}))

const defaultClickHandler = ({ onItemClick, event, item }: DefaultClickHandlerType) =>
    onItemClick ? onItemClick({ item, event }) : item;

const defaultItemRenderer = (item: MenuItemType, styles: CssClassesType, onItemClick?: Function) => {
    const { title, onClick } = item;

    return (
        <div
            key={item.id}
            onClick={event => onClick
                ? onClick({ event, item })
                : defaultClickHandler({ onItemClick, event, item })
            }
            className={styles.item}
        >
            <p className={styles.text}>{title}</p>
        </div>
    )
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
    isOpen,
    items,
    className,
    itemRenderer,
    onItemClick,
}) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    return isOpen ? (
        <div className={classNames(styles.menu, className)}>
            {items.map(item => itemRenderer
                ? itemRenderer(item, onItemClick)
                : defaultItemRenderer(item, styles, onItemClick)
            )}
        </div>
    ) : null
};
