import { createUseStyles } from 'react-jss';
import color from 'color';

import { ThemeProps } from '@styles/theme';
import { flex, stickyFormBlock } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    stepNameActive: {},

    modal: {
        width: 540,
        maxHeight: 'calc(100vh - 50px)',
        overflowY: 'auto',
    },

    modalHeading: {
        ...flex('row', 'space-between', 'center'),
        marginBottom: theme.spacing(7),
    },

    loader: {
        color: theme.colors.violet_primary,
        marginLeft: theme.spacing(2),
    },

    h2: {
        fontFamily: theme.font.secondary,
        fontSize: 24,
        lineHeight: 1.33,
        color: theme.colors.black,
        fontWeight: 'normal',
    },

    validationFailed: {
        ...flex('row', '', 'center'),
        marginBottom: theme.spacing(3),
        color: theme.colors.grey_text,
        fontSize: 16,
    },

    failIcon: {
        marginRight: theme.spacing(3),
        color: theme.colors.red,
    },

    tabsHeader: {
        ...flex('row', 'space-between', 'center'),
        marginBottom: theme.spacing(4),
        borderBottom: `1px solid ${theme.colors.grey_lighter}`,
    },

    deviceName: {
        fontSize: 16,
        color: theme.colors.black,
        marginBottom: theme.spacing(4),
        ...flex('row', 'flex-start', 'center'),
    },

    deviceImage: {
        marginRight: theme.spacing(3),
    },

    addDeviceButtonWrapper: {
        display: 'flex',
    },

    addDeviceButton: {
        color: theme.colors.violet_primary,
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: theme.colors.icon_background,
        },
    },

    addDeviceButtonDisabled: {
        opacity: 0.5,
        cursor: 'default',
    },

    field: {
        marginBottom: theme.spacing(6),
    },

    fieldWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },

    dropdown: {
        margin: 0,
    },

    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(8),
    },

    end: {
        justifyContent: 'flex-end',
    },

    button: {
        marginLeft: theme.spacing(5),
    },

    errors: {
        ...stickyFormBlock(),
    },

    error: {
        backgroundColor: theme.colors.red,
        marginBottom: theme.spacing(2),
    },

    hidden: {
        display: 'none',
    },

    processStepsWrapper: {
        marginBottom: theme.spacing(8),
        ...flex('row', 'flex-start', 'center'),
    },

    longDash: {
        display: 'inline-block',
        width: 20,
        height: 2,
        backgroundColor: theme.colors.grey_light,
        margin: [0, theme.spacing(4)],
    },

    stepName: {
        fontSize: 20,
        lineHeight: 1.4,
        color: theme.colors.grey_text,

        '&:hover': {
            cursor: 'pointer',
        },

        '&$stepNameActive': {
            color: theme.colors.black,
        },
    },

    tabsWrapper: {
        marginBottom: theme.spacing(4),
    },

    uploadPanel: {
        minHeight: 298,
        padding: [theme.spacing(6), 0],
        ...flex('column', 'center', 'center'),
        border: `1px dashed ${color(theme.colors.violet_darker).fade(0.5)}`,
        borderRadius: theme.radius,
        outline: 'none',
    },

    uploadPanelInner: {
        width: 330,
    },

    uploadProcessButton: {
        fontSize: 20,
        lineHeight: 1.4,
        color: theme.colors.violet_primary,
        fontFamily: theme.font.secondary,
        marginRight: theme.spacing(6),
        cursor: 'pointer',
    },

    dragDropDescription: {
        fontSize: 16,
        color: theme.colors.black,
        ...flex('row', 'flex-start', 'center'),
    },

    dropProcedureText: {
        marginRight: theme.spacing(2),
    },

    uploadInProgress: {
        fontSize: 16,
        color: theme.colors.grey_text,
        fontFamily: theme.font.secondary,
        marginBottom: theme.spacing(8),
        ...flex('row', 'flex-start', 'center'),
    },

    uploadIcon: {
        marginRight: theme.spacing(3),
        color: theme.colors.grey_darker,
    },

    uploadIconProgress: {
        marginRight: theme.spacing(3),
        color: theme.colors.violet_primary,
    },

    progressBarWrapper: {
        marginBottom: theme.spacing(8),
    },

    confirmationPanel: {
        paddingTop: theme.spacing(4),
    },

    confirmationPanelText: {
        fontSize: 20,
        lineHeight: 1.4,
        color: theme.colors.black,
        marginBottom: theme.spacing(6),
    },

    confirmationPanelActions: {
        ...flex('row', 'flex-end', 'center'),

        '& button': {
            marginLeft: theme.spacing(5),
        },
    },
}));
