import React, { FC, useState } from 'react';
import moment from 'moment';
import color from 'color';
import { DateRange, DefinedRange } from 'react-date-range';
import { Form, Formik } from 'formik';
import { Button } from '@shared/components/Button';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import Typography from '@material-ui/core/Typography';
import { useTheme } from 'react-jss';
import { ClickOutsideListener } from '@ace/core';
import { useUserAnalyticUIStore } from '@core/useStores';
import { FormSchema } from '@shared/components/forms/FormSchema';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { useStyles } from './UserAnalyticsFilter.styles';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DATE_MMM_DD_YYYY } from '@shared/constants'
import { COLOR_GREY_LIGHTER } from '@styles/constants';
import { messages } from 'Analytics/analytic.messages';
import { AnalyticsExport } from '../AnalyticsExport';
import { UserExecutionsExport } from '../UserExecutionsExport';
import classNames from 'classnames';

class FormValues {
    startDate = '';
    endDate = '';
}

type FilterFormSchema = FormSchema<FormValues>;

const valueSchema: FilterFormSchema = {
    startDate: {
        isRequired: true,
        fieldName: 'startDate',
        placeholder: '',
    },
    endDate: {
        isRequired: true,
        fieldName: 'endDate',
        placeholder: '',
    },
}

const basicRangesList = [
    {
        label: messages['analyticsFilter.lastWeek'],
        value: moment.utc().subtract(6, 'days').startOf('day').format(),
    },
    {
        label: messages['analyticsFilter.lastMonth'],
        value: moment.utc().subtract(1, 'months').startOf('day').format(),
    },
    {
        label: messages['analyticsFilter.lastThreeMonths'],
        value: moment.utc().subtract(3, 'months').startOf('day').format(),
    },
    {
        label: messages['analyticsFilter.lastYear'],
        value: moment.utc().subtract(1, 'years').startOf('day').format(),
    },
    {
        label: messages['analyticsFilter.allTime'],
        value: moment.utc().subtract(20, 'years').startOf('day').format(),
    }
]

const CalendarPrefix: FC = () => {
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.calendarIcon}>
            <DateRangeOutlinedIcon />
        </div>
    )
}

export const UserAnalyticsFilter: FC = () => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const userAnalyticUIStore = useUserAnalyticUIStore();

    const [isOpen, toggleIsOpen] = useState(false);
    const [state, setState] = useState({
        startDate: new Date(userAnalyticUIStore.requestParams?.getParams.startDate),
        endDate: new Date(userAnalyticUIStore.requestParams?.getParams.endDate),
        key: 'selection',
    });

    const onDatePickerChange = (item: Record<string, any>) => {
        setState(item.selection);
    }

    const onFilterSubmit = (values: FormValues) => {
        const startDate = new Date(values.startDate).toISOString();
        const endDate = new Date(values.endDate).toISOString();
        const endDateEOD = new Date(new Date(values.endDate).setDate(new Date(values.endDate).getDate() + 1)).toISOString();

        userAnalyticUIStore.setParams({
            startDate,
            endDate,
        });

        const params = userAnalyticUIStore.requestParams.getParams;

        userAnalyticUIStore.loadUserProceduresExecutions({
            userId: params.userId,
            startDate,
            endDate: endDateEOD,
        });
        toggleIsOpen(false);
    }

    const staticRanges = basicRangesList.map(item => {
        const startDate = new Date(item.value);
        const endDate = new Date(moment.utc().startOf('day').format());

        return {
            label: item.label,
            range: () => ({
                startDate,
                endDate,
            }),
            isSelected() {
                return +new Date(startDate) === +new Date(state.startDate) &&
                    +new Date(endDate) === +new Date(state.endDate);
            },
        }
    })

    return (
        <div className={styles.body}>
            <Typography variant="h6" className={classNames(styles.filterTitle)}>
                {messages['analyticsFilter.title']}
            </Typography>
            <Formik
                onSubmit={onFilterSubmit}
                initialValues={{
                    startDate: moment(state.startDate).format(DATE_MMM_DD_YYYY),
                    endDate: moment(state.endDate).format(DATE_MMM_DD_YYYY),
                }}
                enableReinitialize
            >
                {() => (
                    <>
                        <Form className={styles.form} autoComplete='off' noValidate>
                            <p className={styles.title}>{messages['analyticsFilter.dateRange']}</p>
                            <div className={styles.fields} onClick={() => toggleIsOpen(!isOpen)}>
                                <span className={styles.field}>
                                    <CalendarPrefix />
                                    {moment(state.startDate).format(DATE_MMM_DD_YYYY)} - {moment(state.endDate).format(DATE_MMM_DD_YYYY)}
                                </span>
                                <FormikTextInput
                                    schema={{ ...valueSchema.startDate, className: styles.hidden }}
                                />
                                <FormikTextInput
                                    schema={{ ...valueSchema.endDate, className: styles.hidden }}
                                />
                            </div>
                            {isOpen && (
                                <ClickOutsideListener
                                    outsideClickIgnoreClass={styles.fields}
                                    onClickOutside={() => toggleIsOpen(false)}
                                >
                                    <div className={styles.calendar}>
                                        <DateRange
                                            displayMode="dateRange"
                                            onChange={onDatePickerChange}
                                            months={2}
                                            ranges={[state]}
                                            rangeColors={[`${color(COLOR_GREY_LIGHTER).fade(0.25)}`]}
                                        />
                                        <div className={styles.controls}>
                                            <DefinedRange
                                                onChange={onDatePickerChange}
                                                ranges={[state]}
                                                staticRanges={staticRanges}
                                            />
                                            <Button className={styles.button} type="submit">
                                                {messages['analyticsFilter.apply']}
                                            </Button>
                                        </div>
                                    </div>
                                </ClickOutsideListener>
                            )}
                        </Form>
                    </>
                )}
            </Formik>
            <div className={classNames(styles.multipleExportButtons)}>
                <AnalyticsExport />
                <UserExecutionsExport />
            </div>
        </div>
    )
}
