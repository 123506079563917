import * as Yup from 'yup';

import { UserInfoFormValues } from './UserInfoForm';
import { FIELD_NAMES } from './valuesSchema';

import { messages } from '../../profile.messages';

export const errors = {
    email: messages['profileForm.error.email'],
    required: messages['profileForm.error.required'],
};

export const validationSchema = Yup.object().shape<Partial<UserInfoFormValues>>({
    email: Yup.string().email(errors.email).required(errors.required(FIELD_NAMES.email)),
    firstName: Yup.string().required(errors.required(FIELD_NAMES.firstName)),
    lastName: Yup.string().required(errors.required(FIELD_NAMES.lastName)),
});
