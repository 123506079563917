import React, { FC } from 'react';
import { Observer } from 'mobx-react';
import { getEnv } from 'mobx-state-tree';
import { useTheme, createUseStyles } from 'react-jss';

import { IStoresEnv } from '@core/storesEnv';
import { ThemeProps } from '@styles/theme';
import { ALL_USERS_ID, USERS_WITHOUT_GROUP_ID } from '@shared/constants';
import { SidebarFilterButton } from '@shared/components/SidebarFilterButton';
import { useUsersGridUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { messages } from 'Users/users.messages';

const useStyles = createUseStyles((theme: ThemeProps) => ({
    wrapper: {
        marginBottom: theme.spacing(4),
    },

    menuItem: {
        marginBottom: theme.spacing(2),
    },
}));

export const UsersSidebar: FC = () => {
    const theme = useTheme();
    const styles = useStyles({ theme });

    const usersGridUIStore = useUsersGridUIStore();
    const userPermissionsUIStore = useUserPermissionsUIStore();

    const { groups } = getEnv<IStoresEnv>(usersGridUIStore);

    const setGroupFilter = (inGroup: number) => {
        usersGridUIStore.setParams({
            page: 0,
            inGroup,
            inGroupId: undefined,
        });

        groups.unselectGroup();
    }

    return (
        <Observer>
            {() => {
                const filters = usersGridUIStore.requestParams.getParams;

                return (
                    <div className={styles.wrapper}>
                        <SidebarFilterButton
                            className={styles.menuItem}
                            active={filters.inGroup === ALL_USERS_ID}
                            onClick={() => setGroupFilter(ALL_USERS_ID)}
                        >
                            {messages['usersSidebar.button.allUsers']}
                        </SidebarFilterButton>

                        {
                            !userPermissionsUIStore.isPlayer && (
                                <SidebarFilterButton
                                    className={styles.menuItem}
                                    active={filters.inGroup === USERS_WITHOUT_GROUP_ID}
                                    onClick={() => setGroupFilter(USERS_WITHOUT_GROUP_ID)}
                                >
                                    {messages['usersSidebar.button.usersWithoutGroups']}
                                </SidebarFilterButton>
                            )
                        }
                    </div>
                )
            }}
        </Observer>
    )
}
