import { RouteProps, Route, Redirect } from 'react-router-dom';
import React from 'react';

import { useUserProfileUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { RoutePaths } from '@core/routes/RoutePaths';
import { PagesRoutes } from '@core/routes/PagesRoutes';
import { PagesNames } from '@core/routes/PagesNames';

interface RoutePropsExtended extends RouteProps {
    page: string;
};

export const ProtectedRoute = ({ page, children, ...rest }: RoutePropsExtended) => {
    const userProfileUIStore = useUserProfileUIStore();
    const userPermissionsUIStore = useUserPermissionsUIStore();

    const isPageAvailable = userPermissionsUIStore.isPageAvailable(page);
    const firstAvailablePage = userPermissionsUIStore.firstAvailablePage;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                userProfileUIStore.isAuthentificated
                    ? isPageAvailable
                        ? children
                        : (
                            <Redirect
                                to={{
                                    pathname: PagesRoutes.get(firstAvailablePage || PagesNames.Login),
                                }}
                            />
                        )
                    : (
                        <Redirect
                            to={{
                                pathname: RoutePaths.login,
                                state: { from: location },
                            }}
                        />
                    )
            }
        />
    );
}
