import { createUseStyles } from 'react-jss';

import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
    stats: {
        padding: [0, theme.spacing(6), theme.spacing(2), theme.spacing(6)],
        fontSize: 16,
        lineHeight: 1.5,
        color: theme.colors.grey_text,
        fontWeight: 600,
    },

    executedText: {
        color: theme.colors.yellow,
        fontWeight: 700,
    },

    progress: {
        ...flex('row', 'stretch', 'stretch'),
        overflow: 'hidden',
    },

    progressBlock: {
        height: 6,
        width: '100%',
        overflow: 'hidden',
        borderRadius: theme.radius / 2,
        background: theme.colors.grey_light,
        border: `1px solid ${theme.colors.white}`,
    },

    progressBlockExecuted: {
        background: theme.colors.yellow,
    },

    progressExecutedMark: {
        height: 4,
        background: theme.colors.yellow,
        borderRadius: theme.radius / 2,
    },
}));
