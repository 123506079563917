import { Instance, SnapshotIn, types, getEnv, flow } from 'mobx-state-tree';
import { autorun } from 'mobx';

import { IStoresEnv } from '@core/storesEnv';
import { UserProfileStore } from './UserProfile.store';
import { otpResponseType } from './UserProfile.store';


export const UserProfileUIStoreInferred = types
    .model('UserProfileUIStoreInferred', {
        _userProfile: types.optional(UserProfileStore, {}),
        otpModalOpened: types.optional(types.boolean, false),
        isLoading: types.optional(types.boolean, false),
        isPollingValidOtp: types.optional(types.boolean, false),
        otpInstructions: types.maybeNull(types.string),
        otp: types.maybeNull(types.string),
        otpExpiration: types.maybeNull(types.Date), // DateTime???
        otpRefreshUrl: types.maybeNull(types.string),
        otpLaunchUrl: types.maybeNull(types.string),
    })
    .views(self => {
        const { api } = getEnv<IStoresEnv>(self);
        const { auth } = getEnv(api);

        return {
            get isAuthentificated() {
                return !!auth.authHeaderValue;
            },

            get userInfo() {
                return auth.userProfile;
            },
        }
    })
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);
        const { auth } = getEnv(api);

        const setOtpData = (otpData: otpResponseType | null) => {
            if (otpData != null) {
                self.otpInstructions = otpData.instructions;
                self.otp = otpData.otp;
                self.otpExpiration = new Date(otpData.expiration);
                self.otpRefreshUrl = otpData.refreshUrl;
                self.otpLaunchUrl = otpData.launchUrl;
            }
            else {
                self.otpInstructions = null;
                self.otp = null;
                self.otpExpiration = null;
                self.otpRefreshUrl = null;
                self.otpLaunchUrl = null;
            }
        };

        const signOut = flow(function* () {
            setOtpData(null);
            yield auth.signOut();
        });

        const requestOtp = flow(function* (payload) {
            if (payload == null) {
                payload = self._userProfile.getDefaultOtpPayload();
            }

            self.isLoading = true;
            yield self._userProfile.requestOtp(payload);
            setOtpData(self._userProfile.otp);
            self.isLoading = false;

            // Start listening if OTP has been revoked or used
            self.isPollingValidOtp = true;
            let keepPolling = true;
            const intervalId = setInterval(async () => {
                if (!keepPolling || !self.isPollingValidOtp) {
                    // OTP invalid (Either used or revoked), stop polling
                    self.otpModalOpened = false;
                    setOtpData(null);
                    clearInterval(intervalId);
                } else {
                    // OTP stil valid, continue polling
                    keepPolling = await self._userProfile.validateOtp(self.otp); // Can't access directly self.isPollingValidOtp because it sees that as a "non-mobx action"
                }
            }, 2000); // Do this every second
        });

        const forceStopOtpPolling = () => {
            self.isPollingValidOtp = false;
        };

        const refreshOtp = flow(function* () {
            self.isLoading = true;
            yield self._userProfile.refreshOtp(self.otpRefreshUrl);
            setOtpData(self._userProfile.otp);
            self.isLoading = false;
        });
        
        const toggleOtpModalOpened = (open: boolean) => {
            self.otpModalOpened = open;
        };

        const cleanUpOtpModal = () => setOtpData(null);

        return {
            signOut,
            requestOtp,
            forceStopOtpPolling,
            refreshOtp,
            toggleOtpModalOpened,
            cleanUpOtpModal,
        }
    })

type UserProfileUIStoreFactoryType = typeof UserProfileUIStoreInferred;
interface IUserProfileUIStoreFactory extends UserProfileUIStoreFactoryType { }
export const UserProfileUIStore: IUserProfileUIStoreFactory = UserProfileUIStoreInferred;
export interface IUserProfileUIStore extends Instance<IUserProfileUIStoreFactory> { }
export interface IUserProfileUIStoreSnapshotIn extends SnapshotIn<IUserProfileUIStore> { }
