import { Modal } from '@ace/core';

import React from 'react';
import { useTheme } from 'react-jss';
import { RegisterForm } from './components/RegisterForm';
import { useStyles } from './Register.styles';
export type RegisterModalProps = {
    isOpen: boolean,
    onRequestClose: () => void,
}
export const Register: React.FC<RegisterModalProps> = ({ isOpen, onRequestClose }) => {
    const theme = useTheme();
    const styles = useStyles({ theme });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
        >
            <RegisterForm />
        </Modal>
    )
};