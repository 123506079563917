import { Instance, SnapshotIn, getSnapshot, types, flow } from 'mobx-state-tree';

import { UserStore } from './User.store';
import { ResendInvitationInputData } from '../domain/ResendInvitationInputData';

const ResendInvitationInputDataModel = types.maybeNull(
    types.model('resendInvitationInputData', {
        id: types.string,
        email: types.string,
        fullName: types.string,
    })
);

export const UserUIStoreInferred = types
    .model('UserUIStoreInferred', {
        _user: types.optional(UserStore, {}),
        isLoading: types.optional(types.boolean, true),
        userToEditId: types.maybeNull(types.string),
        userModalOpened: types.optional(types.boolean, false),
        deleteConfirmationModalOpened: types.optional(types.boolean, false),
        resendInvitationModalOpened: types.optional(types.boolean, false),
        resendInvitationInputData: ResendInvitationInputDataModel,
        errors: types.maybeNull(types.array(types.string)),
    })
    .views(self => ({
        get user() { return self._user; },
        get userUIData() { return getSnapshot(self) },
    }))
    .actions(self => {
        const editUser = flow(function* (payload) {
            const result = yield self._user.editUser(payload);

            if (!result.success) {
                const { body } = result.error || {};

                self.errors = body?.errors;
            } else {
                self.errors = null;
            }

            return result;
        });

        const loadUser = flow(function* (payload) {
            return yield self._user.loadUser(payload);
        });

        const deleteUser = flow(function* (payload) {
            return yield self._user.deleteUser(payload);
        });

        const exportUser = flow(function* (payload) {
            return yield self._user.exportUser(payload);
        });

        const toggleUserModalOpen = (open: boolean) => {
            self.userModalOpened = open;
        };

        const toggleDeleteConfirmationModalOpened = (open: boolean) => {
            self.deleteConfirmationModalOpened = open;
        };

        const setEditUserId = (id: string | null | undefined) => {
            if (id) {
                self.userToEditId = id;
            }
        }

        const clearEditUserId = () => self.userToEditId = null;

        const setResendInvitationInputData = (data: ResendInvitationInputData | null) => {
            self.resendInvitationInputData = data;
        }

        const toggleResendInvitationModalOpened = (open: boolean) => {
            self.resendInvitationModalOpened = open;
        }

        return {
            loadUser,
            editUser,
            deleteUser,
            exportUser,
            toggleUserModalOpen,
            toggleDeleteConfirmationModalOpened,
            setEditUserId,
            clearEditUserId,
            setResendInvitationInputData,
            toggleResendInvitationModalOpened,
        }
    });

type UserUIStoreFactoryType = typeof UserUIStoreInferred;
interface IUserUIStoreFactory extends UserUIStoreFactoryType { }
export const UserUIStore: IUserUIStoreFactory = UserUIStoreInferred;
export interface IUserUIStore extends Instance<IUserUIStoreFactory> { }
export interface IUserUIStoreSnapshotIn extends SnapshotIn<IUserUIStore> { }
