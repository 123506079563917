import * as Yup from 'yup';

import { FormValues } from './FormValues';
import { valuesSchema } from './valuesSchema';
import { messages } from 'AuthPart/authpart.messages';

export const errors = {
    email: messages['forgotPasswordForm.validation.email'],
    required: messages['forgotPasswordForm.validation.required'],
};

export const validationSchema = Yup.object().shape<FormValues>({
    email: Yup.string().email(errors.email).required(errors.required(valuesSchema.email.label)),
    password: Yup.string().required(errors.required(valuesSchema.password.label)),
});
