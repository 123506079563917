import React from 'react';
import { useTheme } from 'react-jss';

import { useStyles } from './ProgressBar.styles';

export interface ProgressBarProps {
    text?: string;
    progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress, text }) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    return (
        <div className={styles.wrapper}>
            <div className={styles.info}>
                <span className={styles.text} data-testid="progress-bar-title">{text}</span>
                <span
                    className={styles.progressNumber}
                    data-testid="progress-bar-progress"
                >
                    {progress}%
                </span>
            </div>

            <div className={styles.trackWrapper}>
                <div className={styles.progress} style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    )
};

ProgressBar.displayName = 'ProgressBar';
