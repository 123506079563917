import { Instance, SnapshotIn, types } from 'mobx-state-tree';

export const UserGroupInferred = types
    .model('UserGroupInferred', {
        id: types.identifier,
        name: types.string,
    });

type UserGroupFactoryType = typeof UserGroupInferred;
interface IUserGroupFactory extends UserGroupFactoryType { }
export const UserGroup: IUserGroupFactory = UserGroupInferred;
export interface IUserGroup extends Instance<IUserGroupFactory> { }
export interface IUserGroupSnapshotIn extends SnapshotIn<IUserGroup> { }
