import { EndpointCallParams } from '@core/api/endpointCallParams';

import { IApiStore } from '@core/api/apiStore';

export const performGetProceduresDevices = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const { errorHandlers } = params;
    const req = api
        .get('content-app/api/v1/Filters/Devices', {
            errorHandlers,
            payload: {},
        })

    return req;
}

export const performGetProceduresModes = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const { errorHandlers } = params;
    const req = api
        .get('content-app/api/v1/Filters/Modes', {
            errorHandlers,
            payload: {},
        })

    return req;
}

export const performGetProceduresTypes = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const { errorHandlers } = params;
    const req = api
        .get('content-app/api/v1/Filters/Types', {
            errorHandlers,
            payload: {},
        })

    return req;
}

export const performGetProceduresOwnerships = async (api: IApiStore, params: EndpointCallParams<{}>) => {
    const { errorHandlers } = params;
    const req = api
        .get('content-app/api/v1/Filters/Ownerships', {
            errorHandlers,
            payload: {},
        })

    return req;
}
