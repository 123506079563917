import { FormSchema } from '@shared/components/forms/FormSchema';

import { LicenseFormValues } from './LicenseModal';
import { messages } from 'Licenses/license.messages';

type LicenseFormSchema = FormSchema<LicenseFormValues>;

export const FIELD_NAMES = {
    name: messages['licenseModal.name.label'],
    unityId: messages['licenseModal.unityId.label'],
}

export const valuesSchema: LicenseFormSchema = {
    name: {
        fieldName: 'name',
        label: FIELD_NAMES.name,
        isRequired: true,
    },
    unityId: {
        fieldName: 'unityId',
        label: FIELD_NAMES.unityId,
        isRequired: true,
    },
};
