import { EndpointCallParams } from '@core/api/endpointCallParams.ts';

import { IApiStore } from '@core/api/apiStore';

type PageParams = {
  limit: number,
  page: number,
}
type SortParams = {
  sortDirection: number,
  sortFieldName: string,
}
type SearchParams = {
  searchText: string,
}



export type GetLicensesParams = PageParams & SortParams & SearchParams;

export const performGetLicenses = async (api: IApiStore, params: EndpointCallParams<GetLicensesParams>) => {
  const { payload, ...rest } = params;
  const req = api
    .post('uiaggregator-app/api/v1/Licenses/Search', {
      ...rest,
      payload,
    })

  return req;
}
