import React from 'react'
import { Observer } from 'mobx-react';
import { useTheme } from 'react-jss';
import { Form, FormikProps } from 'formik';

import { useLoginUIStore, useRegisterUIStore } from '@core/useStores';
import { RoutePaths } from '@core/routes/RoutePaths';
import { Button } from '@shared/components/Button';
import { Link } from '@shared/components/Link';
import { FormikTextInput } from '@shared/components/forms/formikWrappers/FormikTextInput';
import { FormikPasswordInput } from '@shared/components/forms/formikWrappers/FormikPasswordInput';
import { FormError } from '@shared/components/FormError';

import { FormValues } from './FormValues';
import { valuesSchema } from './valuesSchema';
import { loginErrors, DefaultError } from './errors';
import { APP_EXTERNAL_STYLE_VARIANT } from '@shared/components/forms/BaseInputProps';

import { useStyles } from './LoginForm.styles';
import { messages } from 'AuthPart/authpart.messages';
import { Register } from 'Register/Register';

export interface LoginFormControlsProps extends FormikProps<FormValues> { };

export const LoginFormControls: React.FC<LoginFormControlsProps> = ({ errors, values }) => {
    const theme = useTheme();
    const styles = useStyles(theme);

    const loginUiStore = useLoginUIStore();
    const formErrors = Object.values(errors);
    const submitEnabled = values.email && values.password;
    const registerUiStore = useRegisterUIStore();
    const onRegisterModalOpen = () => {
        registerUiStore.toggleRegisterModalOpen(true);
    }
    const onRegisterClose = () => {
        registerUiStore.toggleRegisterModalOpen(false);
    }

    return (
        <>
            <Observer>{() => {
                return (
                    <Register isOpen={registerUiStore.registerModalOpen} onRequestClose={onRegisterClose}></Register>
                )
            }}
            </Observer>
            <Form autoComplete='off' noValidate>
                <Observer>
                    {() => (
                        <div className={styles.errorWrapper}>
                            {
                                loginUiStore.status.isError && (
                                    <FormError
                                        text={loginErrors.get(loginUiStore.authError) || DefaultError}
                                    />
                                )
                            }
                        </div>
                    )}
                </Observer>

                {
                    formErrors && (
                        <div className={styles.errorWrapper}>
                            {
                                formErrors.map((error, index) => (
                                    <FormError key={index} text={error || ''} />
                                ))
                            }
                        </div>
                    )
                }

                <div className={styles.formWrapper}>
                    <div className={styles.fieldWrapper}>
                        <FormikTextInput
                            schema={valuesSchema.email}
                            cleanable={true}
                            autoFocus={true}
                            styleVariant={APP_EXTERNAL_STYLE_VARIANT}
                        />
                    </div>

                    <div className={styles.fieldWrapper}>
                        <FormikPasswordInput
                            schema={valuesSchema.password}
                            styleVariant={APP_EXTERNAL_STYLE_VARIANT}
                        />
                    </div>

                    <div className={styles.forgotPassWrapper}>
                        <Link to={RoutePaths.forgotPassword}>{messages['loginForm.forgotPassword.title']}</Link>
                    </div>

                    <div className={styles.actionWrapper}>
                        <Observer>
                            {() => (
                                <>
                                    <Button
                                        type='submit'
                                        styleVariant="appExternal"
                                        disabled={!submitEnabled}
                                        loading={loginUiStore.status.isLoading}
                                    >
                                        {messages['loginForm.login']}
                                    </Button>
                                    <br /> <br />
                                    <Button
                                        type='button'
                                        styleVariant="appExternal"
                                        onClick={onRegisterModalOpen}
                                    >
                                        {messages['loginForm.register']}
                                    </Button>
                                </>
                            )}

                        </Observer>
                    </div>
                </div>
            </Form>
        </>
    )
}
