import React from 'react';
import { Formik } from 'formik';
import { FormValues } from './FormValues';
import { RegisterFormControls } from './RegisterFormControls';
import { validationSchema } from './validationSchema';
import { useTheme } from 'react-jss';
import { useStyles } from './RegisterForm.styles';
import { messages } from '../register.messages';
import { useRegisterUIStore } from '@core/useStores';
import { RegisterTrialParams } from 'Register/service';
import { toast } from 'react-toastify';
import { ToastMessage } from '@shared/components/Toast';


export const RegisterForm: React.FC = () => {
    const theme = useTheme();
    const styles = useStyles({ theme });
    const registerUiStore = useRegisterUIStore();
    const registerUser = async (values: FormValues) => {
        if (registerUiStore.captcha) {
            const computedValues: RegisterTrialParams = {
                companyName: values.companyName,
                firstName: values.name,
                lastName: values.surname,
                email: values.email,
                department: values.department,
                companyDescription: values.companyDescription,
                captcha: registerUiStore.captcha,
            };
            const result = await registerUiStore.register(computedValues);
            if (result.success) {
                toast.success(
                    <ToastMessage
                        type='success'
                        message={messages['registerForm.success']}
                    />
                );
                registerUiStore.toggleRegisterModalOpen(false);
            } else {
                registerUiStore.resetCaptcha();
                toast.error(
                    <ToastMessage
                        type='error'
                        message={result.error.body.errors[0]}
                    />
                );
            }
        } else {
            toast.error(
                <ToastMessage
                    type='error'
                    message={messages['registerForm.errorCaptcha']}
                />
            );
        }
    }

    return (
        <>
            <h2 className={styles.h2}>
                {messages['registerForm.title']}
            </h2>
            <div className={styles.viewWrapper}>

                <Formik
                    onSubmit={registerUser}
                    initialValues={new FormValues()}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {props => <RegisterFormControls {...props} />}
                </Formik>
            </div>
        </>
    )
}
