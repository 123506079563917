import React, { useEffect, useState } from 'react';
import { useTheme } from 'react-jss';
import _ from 'lodash';
import DownloadIcon from '@assets/download.png';
import SwitchIcon from '@assets/turn-off.png';

import { useStyles } from './LicensesPage.styles';
import { messages } from 'Licenses/license.messages';
import { Cell, Column } from 'react-table';
import ArrowRightIcon from '@assets/arrow-right.svg';
import classNames from 'classnames';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import { useLicensesUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { Observer } from 'mobx-react';
import { LicensesSummary } from 'Licenses/components/LicenseSummary';
import { SearchForm } from '@shared/components/SearchForm';
import { Table } from '@shared/components/Table';
import { NoResultsView } from '@shared/components/NoResultsView';
import { LicenseModal } from 'Licenses/components/LicenseModal';

import moment from 'moment';
import { DATE_MMM_DD_YYYY } from '@shared/constants';
type SubComponentProps = {
    row: {
        originalSubRows: Array<{
            unityId: string,
            allUser: string,
            key: string
        }>,
    }
}
type FetchDataType = {
    pageIndex: number;
    sortBy: {
        id: string;
        desc: boolean;
    }[]
}

const EXPANDER_CELL_WIDTH = 24;
const NoInfo = () => {
    const styles = useStyles();

    return <div className={styles.noInfo}>{messages['licensePage.noInfo']}</div>
}


const columnsData = [
    {
        id: 'expander',
        minWidth: 40,
        maxWidth: 40,
        Header: () => (
            <div style={{ minWidth: EXPANDER_CELL_WIDTH }} />
        ),
        Cell: ({ row }: Cell) => {
            const { style, ...props } = row.getToggleRowExpandedProps();
            const styles = useStyles();

            return (
                <div
                    {...props}
                    style={{
                        ...style,
                        minWidth: EXPANDER_CELL_WIDTH,
                        display: 'flex',
                    }}
                >
                    {row.canExpand && (
                        <img
                            src={ArrowRightIcon}
                            className={classNames(styles.expandIcon, { [styles.expanded]: row.isExpanded })}
                        />
                    )}
                </div>
            )
        },
        width: EXPANDER_CELL_WIDTH + 16,
        disableSortBy: true,
    },
    {
        Header: messages['licensePage.table.header.name'],
        accessor: 'name',
        width: 18,
        maxWidth: 150,
        Cell: ({ row, value }: Cell) => {
            const styles = useStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.licenseName}>{value}</span>
                </div>
            );
        },
    },
    {
        Header: messages['licensePage.table.header.unityId'],
        accessor: 'unityId',
        width: 18,
        maxWidth: 400,
        Cell: ({ row, value }: Cell) => {
            const styles = useStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.licenseName} title={value}>{value}</span>
                </div>
            );
        },
    },
    {
        Header: messages['licensePage.table.header.lastUser'],
        accessor: 'lastUser',
        width: 18,
        maxWidth: 200,
        Cell: ({ row, value }: Cell) => {
            const styles = useStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.licenseName}>{value}</span>
                </div>
            );
        },
    },
    {
        Header: messages['licensePage.table.header.lastLogin'],
        accessor: 'lastLogin',
        width: 18,
        maxWidth: 150,
        Cell: ({ value }: Cell) => value ? moment(value).format(DATE_MMM_DD_YYYY) : <></>,
    },
    {
        Header: messages['licensePage.table.header.usedIn'],
        accessor: 'usedIn',
        width: 18,
        maxWidth: 100,
        Cell: ({ row, value }: Cell) => {
            const styles = useStyles();

            return (
                <div className={styles.nameWrapper}>
                    <span className={styles.licenseName}>{value}</span>
                </div>
            );
        },
    },
    {
        Header: messages['licensePage.table.header.expireDate'],
        accessor: 'expireDate',
        width: 18,
        maxWidth: 150,
        Cell: ({ value }: Cell) => value ? moment(value.replace("T23:59:59Z", "T12:00:00Z")).format(DATE_MMM_DD_YYYY) : <></>,
    },
    {
        id: 'download',
        minWidth: 40,
        maxWidth: 90,
        disableSortBy: true,
        Cell: ({ row }: Cell) => {
            const styles = useStyles();

            const licensesUIStore = useLicensesUIStore();
            const original: Record<string, any> = row.original;

            const onCellClick = () => {
                if (!original.isDisabled) { licensesUIStore.downloadLicense(original.key, original.unityId); }
            }
            const onToggle = async () => {
                const result = await licensesUIStore.toggleLicense(original.unityId, !original.isDisabled);
                if (result.success) {
                    licensesUIStore.resetLicense(result.data.unityId, result.data.isDisabled);
                }
            }
            const userPermissionsUIStore = useUserPermissionsUIStore();
            userPermissionsUIStore.isCompaniesAvailable;

            return (
                <>
                    {!userPermissionsUIStore.isCompaniesAvailable && (<span className={styles.placeholder} ></span>)
                    }
                    <button
                        onClick={() => onCellClick()}
                        className={styles.button}
                        title={messages['licensePage.download']}
                    >
                        <img src={DownloadIcon} style={{ width: 24, height: 24 }} />
                    </button>
                    {userPermissionsUIStore.canDisableLicense && (<button
                        onClick={() => onToggle()}
                        className={styles.button}
                        title={messages['licensePage.switch']}
                    >
                        <img src={SwitchIcon} style={{ width: 24, height: 24 }} />
                    </button>)
                    }
                </>
            )
        },
    },
]

export const LicensesPage: React.FC = () => {
    const licensesUIStore = useLicensesUIStore();
    const theme = useTheme();
    const styles = useStyles({ theme });
    useEffect(() => {
        licensesUIStore.loadStatistics();
    }, []);
    const renderRowSubComponent = React.useCallback(({ row }: SubComponentProps) => {
        const data = row.originalSubRows[0];

        return (
            <div className={styles.bodySubComponent} >
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['licensePage.subRow.unityId']}</h5>
                    <div className={styles.text} style={{ wordBreak: "break-all" }}>
                        {data.unityId || <NoInfo />}
                    </div>
                </div>
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['licensePage.subRow.allUser']}</h5>
                    <div className={styles.text} style={{ wordBreak: "break-all" }}>
                        {data.allUser || <NoInfo />}
                    </div>
                </div>
                <div className={styles.descriptionWrapper}>
                    <h5 className={styles.header}>{messages['licensePage.subRow.key']}</h5>
                    <div className={styles.text} style={{ wordBreak: "break-all" }}>
                        {data.key || <NoInfo />}
                    </div>
                </div>

            </div>
        )
    }, []);
    const initialSortState = {
        sortBy: [],
    };

    const onLicenseModalClose = () => {

        licensesUIStore.toggleLicenseModalOpen(false);
    }

    const onSearchTextChanged = (searchText: string) => {
        licensesUIStore.changeSearchText(searchText);
    };

    const onFetchData = ({ pageIndex, sortBy }: FetchDataType) => {
        if (sortBy && sortBy.length) {
            licensesUIStore.setupSort(sortBy[0].id, Number(sortBy[0].desc));
        }
        licensesUIStore.setupPage(pageIndex);
    };
    const columns: Column<object>[] = React.useMemo(() => columnsData, []);
    const onLicenseModalOpen = () => {
        licensesUIStore.toggleLicenseModalOpen(true);
    }

    return (
        <>
            <LicensesSummary statistics={licensesUIStore.statistics} />

            <div className={styles.tableActions}>
                <div className={styles.invite}>
                    <h4 className={styles.h4}>{messages['licensePage.title']}</h4>
                    <span style={{ display: 'inherit' }} title={messages['licensePage.createLicense.title']}>
                        <AddOutlinedIcon
                            style={{ fontSize: 24 }}
                            className={styles.addIcon}
                            onClick={onLicenseModalOpen}
                        />
                    </span>
                </div>
                <div className={styles.filters}>
                    <Observer>
                        {() => (
                            <SearchForm
                                initialValues={{
                                    search: licensesUIStore.searchText
                                        ? licensesUIStore.searchText
                                        : '',
                                }}
                                onSearchTextChanged={onSearchTextChanged}
                                className={styles.searchWrapper}
                                disabled={licensesUIStore.isLoading}
                            />
                        )}
                    </Observer>
                </div>
            </div>
            <Observer>
                {() => {
                    const data = licensesUIStore.licenses.toJS();
                    const preparedData = data.map(row => ({
                        ...row,
                        subRows: [{
                            unityId: row.unityId,
                            allUser: row.allUser,
                            key: row.key,
                        }],
                    }));

                    return (
                        <>
                            <LicenseModal
                                isOpen={licensesUIStore.licenseModalOpened}
                                onRequestClose={onLicenseModalClose}
                            />
                            {
                                licensesUIStore.licenses && licensesUIStore.licenses.length > 0 && (
                                    <Table
                                        data={preparedData}
                                        columns={columns}
                                        fetchData={onFetchData}
                                        isLoading={licensesUIStore.isLoading}
                                        renderRowSubComponent={renderRowSubComponent}
                                        paginationStatus={{ ...licensesUIStore.pagination }}
                                        initialState={initialSortState}
                                    />
                                )
                            }

                            {
                                (!licensesUIStore.licenses || licensesUIStore.licenses.length == 0) && <NoResultsView entityName='licenses' />
                            }
                        </>
                    );
                }}
            </Observer>
        </>
    )
}

