import { onAction } from 'mobx-state-tree';
import { History } from 'history';
import { toast } from 'react-toastify';

import { NavigationManager } from '@ace/core';

import { GroupsUIStore, GroupsDataStore } from 'Groups/store';

import { LoginUIStore } from 'AuthPart/Login';
import { ForgotPasswordUIStore } from 'AuthPart/ForgotPassword';
import { SetPasswordUIStore } from 'AuthPart/SetPassword';
import { UserPermissionsUIStore } from './Auth/stores/UserPermissionsUI.store';

import { UserProfileStore } from '../Header/components/UserMenu/stores';
import { UserProfileUIStore } from '../Header/components/UserMenu/stores';
import { ProfileUIStore } from 'Profile/stores';

import { CompaniesGridUIStore } from 'Companies/stores';
import { CompanyUIStore } from 'Companies/stores';

import { UsersGridUIStore } from 'Users/stores';
import { UserUIStore } from 'Users/stores';

import { ProceduresGridUIStore } from 'Procedures/stores';
import { ProcedureUIStore } from 'Procedures/stores';

import { AnalyticsGridUIStore, UserAnalyticUIStore } from 'Analytics/stores';

import { FiltersOptionsStore } from '@shared/filtersOptions/stores';
import { getStorage, StorageType } from '@utils/getStorage';
import { createAuthStore } from './Auth/stores';
import { createApiStore } from './api/apiStore';
import { IStoresEnv } from './storesEnv';
import { getURL } from './getUrl';
import { RegisterUIStore } from 'Register/store';
import { DownloadablesUIStore } from 'Download/stores';
import { LicensesUIStore } from 'Licenses/stores';

type StoresOuterDependencies = {
    history: History;
};

export const initializeDependenciesAndCreateStores = async (outerDependencies: StoresOuterDependencies) => {
    const navigator = new NavigationManager(outerDependencies.history);

    const auth = createAuthStore({
        navigator,
        localStorage: getStorage(StorageType.Local),
        sessionStorage: getStorage(StorageType.Session),
    });

    const api = createApiStore({
        auth,
        getURL,
        notifier: {
            error: (message: string) => toast.error(message),
            info: (message: string) => toast.error(message),
        },
    });

    const baseEnv = {
        api,
        navigator,
        localStorage: getStorage(StorageType.Local),
        sessionStorage: getStorage(StorageType.Session),
    };

    const env: IStoresEnv = {
        ...baseEnv,
        groups: GroupsDataStore.create({}, baseEnv),
        filtersOptions: FiltersOptionsStore.create({}, baseEnv),
    };

    const groupsUIStore = GroupsUIStore.create({}, env);
    const companiesGridUIStore = CompaniesGridUIStore.create({}, env);
    const usersGridUIStore = UsersGridUIStore.create({}, env);
    const userProfileStore = UserProfileStore.create({}, env);
    const userProfileUIStore = UserProfileUIStore.create({}, env);
    const analyticsGridUIStore = AnalyticsGridUIStore.create({}, env);
    const proceduresGridUIStore = ProceduresGridUIStore.create({}, env);

    onAction(userProfileUIStore, call => {
        if (call.name === 'signOut') {
            groupsUIStore.unselectGroup();
            companiesGridUIStore.cleanUp();
            usersGridUIStore.requestParams.clearAllParams();
            analyticsGridUIStore.requestParams.clearAllParams();
            proceduresGridUIStore.requestParams.clearAllParams();
        }
    });

    return ({
        groupsUIStore,
        loginUIStore: LoginUIStore.create({}, env),
        registerUIStore: RegisterUIStore.create({}, env),
        setPasswordUIStore: SetPasswordUIStore.create({}, env),
        forgotPasswordUIStore: ForgotPasswordUIStore.create({}, env),
        companiesGridUIStore,
        companyUIStore: CompanyUIStore.create({}, env),
        usersGridUIStore,
        userUIStore: UserUIStore.create({}, env),
        userPermissionsUIStore: UserPermissionsUIStore.create({}, env),
        userProfileStore,
        userProfileUIStore,
        profileUIStore: ProfileUIStore.create({}, env),
        proceduresGridUIStore,
        procedureUIStore: ProcedureUIStore.create({}, env),
        analyticsGridUIStore,
        userAnalyticUIStore: UserAnalyticUIStore.create({}, env),
        downloadablesUIStore: DownloadablesUIStore.create({}, env),
        licensesUIStore: LicensesUIStore.create({}, env),
    });
};

export type Stores = UnwrapAsyncFnReturn<typeof initializeDependenciesAndCreateStores>;
